import React, { useState, useEffect, useContext, useRef } from 'react'
import axios from 'axios'
import moment from 'moment';

import ChartDataLabels from 'chartjs-plugin-datalabels';

import { Card, Spin, Radio, Form, Select, Anchor} from 'antd'
import { AppContext } from '../App';

import { DownloadOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    Colors,
    layouts,
} from 'chart.js';

import { Pie } from 'react-chartjs-2';

ChartJS.register(
  ChartDataLabels,
  ArcElement, 
  Tooltip, 
  Legend,
  Colors,
);

const IncomeThisWeek = (homeData) => {

    const token = localStorage.getItem('Authentication');
    const API_URL = process.env.REACT_APP_API_URL;

    const [loadingChart, setLoadingChart] = useState(true)
    const [chartReady, setChartReady] = useState(false)
    const [chartType, setChartType] = useState("week")
    const [totalIncome, setTotalIncome] = useState(0)
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [],
    });

    const [branch, setBranch] = useState([]);
    const [branchSelected, setBranchSelected] = useState('all');

    const [listDateData,setListDateData] = useState([])
    const [listIncomeData,setListIncomeData] = useState([])

    const [form] = Form.useForm();

    // period change
     useEffect(() => {
      
      setChartType(homeData.chartType)
      setChartData({
        labels: [],
        datasets: [],
      })

      FetchIncomeAppByDay(homeData.chartType, branchSelected)

    },[homeData.chartType]);

    // branch change
    useEffect(() => {

      setBranchSelected(homeData.branchSelectedIndex)
      setLoadingChart(true)
      setChartReady(false)
      setChartData({
        labels: [],
        datasets: [],
      })
      FetchIncomeAppByDay(chartType,homeData.branchSelectedIndex)

  }, [homeData.branchSelectedIndex])

    const options = {
        plugins: {
          align: 'center',
          legend: false,
          datalabels: {
            display: true,
            formatter: (val, ctx) => {
              // Grab the label for this value
              const label = ctx.chart.data.labels[ctx.dataIndex];
    
              // Format the number with 2 decimal places
              const formattedVal = Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
              }).format(val);


              const percentVal = (Math.round(((val/totalIncome) * 100 ) * 100) / 100).toFixed(2); 
    
              if(formattedVal != 0){
                return `${label}\n${percentVal}%`;
              }
            },
            color: '#fff',
            backgroundColor: '#404040',
          },
        },
        responsive: true,
    };


   const FetchIncomeAppByDay = async (chartTypeData,branchId) => {

      if(!branchId){branchId = ""}

        try {
            const response = await axios.get(`${API_URL}incomeAppByDay/${chartTypeData}/${branchId}`, {
              headers: {
                'Authorization': `bearer ${token}`,
                'Content-Type': 'application/json'
              }
            })

            const listDate = response.data.listDay
            const listIncome = response.data.listDayIncome

            setListDateData(listDate)
            setListIncomeData(listIncome)

            setTotalIncome(listIncome.reduce((n, {totalIncome}) => n + totalIncome, 0));

            const data = {
                labels: listDate,
                datasets: [
                  {
                    data: listIncome.map((x) => x.totalIncome ? x.totalIncome : null),
                    backgroundColor:["#FF000099","#FFFF0099","#FF00FF99","#00800099","#FFA50099","#87CEEB99","#A020F099"]
                  },
              ]
             };

            setChartData(data);

            setLoadingChart(false)
            setChartReady(true)

          } catch (error) {
            console.log('error', error)
          }
    }


  const downloadData = async () =>{

    var incomeRecord;
    const incomeData = []

    const headerIncome = {
        incomeDate: "วัน",
        incomeTotal: "รายได้ทั้งหมด",
        incomePercent: "คิดเป็นเปอร์เซ็นต์",
    }

    incomeData.push(headerIncome)

    for(let index = 0 ; index < listDateData.length ; index++){
      incomeRecord = {
        incomeDate: listDateData[index],
        incomeTotal: listIncomeData[index].totalIncome,
        incomePercent: listIncomeData[index].totalPercent,
      }

      incomeData.push(incomeRecord)
    }

    // add width
    var wscols = []
    for(let indexWidth = 0 ; indexWidth < 3 ; indexWidth++){
      wscols.push({wch:20})
    }

    const workbook = XLSX.utils.book_new()
    const worksheetIncome = XLSX.utils.json_to_sheet(incomeData, {skipHeader:true})
    worksheetIncome['!cols'] = wscols;


    XLSX.utils.book_append_sheet(workbook, worksheetIncome, 'สัดส่วนรายได้แต่ละวัน')
    XLSX.writeFile(workbook, 'สัดส่วนรายได้แต่ละวัน.xlsx')

  }


    return (
        <Card title="สัดส่วนรายได้แต่ละวัน" extra={<DownloadOutlined onClick={()=>downloadData()}/>}>
            <center> <Spin spinning={loadingChart} /></center>
            {chartReady && <Pie options={options} data={chartData}/> }
        </Card>
    )
}

export default IncomeThisWeek