import React, { useState, useEffect, useContext, useRef } from 'react'
import axios from 'axios'
import { Table, Image, Input, Space, Button, message, Tag, Empty, Modal, Spin, Form, Select, DatePicker} from 'antd'
import moment from 'moment' 
import dayjs from 'dayjs'
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { AppContext } from '../App';
import {getBranch} from './addFanshine'
import { configConsumerProps } from 'antd/es/config-provider';

export var fetchData = ''

const ListTransaction = () => {
  const { user } = useContext(AppContext);

  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem('Authentication');

  const [data, setData] = useState([])
  const [messageApi, contextHolder] = message.useMessage();
  const [emptyBranch, setEmptyBranch] = useState(false)

  const [branch,setBranch] = useState([])
  const [form] = Form.useForm();

  const [openDeleteModal,setOpenDeleteModal] = useState(false)
  const [openEditModal,setOpenEditModal] = useState(false)

  const [fanshineSelect, setFanshineSelect] = useState('')

  const [messageConfirmDelete, setMessageConfirmDelete] = useState('ยืนยันการลบแฟรนไชส์')


  const columns = [
    {
        title: '',
        width: 100,
        dataIndex: 'branchImage',
        key: 'branchImage',
        fixed: 'left',
        render: (text, record) => (
          <div>
            {text ? <Image width={100} src={text} /> : ''}
          </div>
        )    
    },
    {
      title: 'ชื่อสาขา',
      width: 100,
      dataIndex: 'branchName',
      key: 'branchName',
      fixed: 'left',
      render: (text, record) => (
        <div style={{ fontWeight: 600, color: "#3a9bdc" }}>
          {<a href={'/fanshineFeeHistory/'+record.id}>{text}</a>}
        </div>
      )    
    },
    {
        title: 'เจ้าของ',
        width: 80,
        dataIndex: 'branchOwner',
        key: 'branchOwner',
        render: (text, record) => (
          <div>
            {text}
          </div>
        ),
    },
    {
        title: 'ค่าบริการแฟรนไชส์',
        width: 80,
        dataIndex: 'fanshineFee',
        key: 'fanshineFee',
        render: (text, record) => (
          <div>
            {text}%
          </div>
        ),
    },

    {
      title: 'ค่าบริการแอปพลิเคชั่น',
      width: 90,
      dataIndex: 'applicationFee',
      key: 'applicationFee',
      render: (text, record) => (
        <div>
          {text}%
        </div>
      ),
    },
    {
      title: 'ค่าบริการสาขา',
      width: 90,
      dataIndex: 'branchFee',
      key: 'branchFee',
      render: (text, record) => (
        <div>
          {text}%
        </div>
      ),
    },
    {
      title: 'วันที่มีผล',
      width: 90,
      dataIndex: 'effectiveDate',
      key: 'effectiveDate',
      render: (text, record) => (
        <div>
            {moment(text).format('DD/MM/YYYY')}
        </div>
      ),
    },
    {
      title: 'จัดการ',
      width: 90,
      dataIndex: 'id',
      key: 'action',
      render: (text, record) => (
        <div>
            <a href={'/fanshineFeeHistory/'+record.id}><Button type='primary'>แก้ไข</Button></a> <Button onClick={() => deleteFanshineShow(record.id, record.branchName)} danger>ลบ</Button>
        </div>
      ),
    }
  ]

  fetchData = async () => {
    setData('')
    try {
      const response = await axios.get(`${API_URL}getListFanshine`, {
        headers: {
          'Authorization': `bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })

      const adjustedData = response.data.map((fanshine) => ({
        ...fanshine,
        key: fanshine.id,
      }));

      if(response.data.length === 0){
        setEmptyBranch(true)
      }else{
        setEmptyBranch(false)
      }

      setData(adjustedData)
    } catch (error) {
      console.log('error', error)
    }
    }

    useEffect(() => { 
        fetchData()
        getBranchList()
    }, [])

    const getBranchList = async () => {
        try {
            const response = await axios.get(`${API_URL}getNewBranchFanshine`,
                {
                    headers: { 'Authorization': `bearer ${token}` }
                })
            setBranch(response.data);

        } catch (error) {
            console.log(error);
        }
    }

    const editFanshineShow = async (fanshineId, branchName, fanshineFeeHistoryId, fanshineFee, applicationFee, branchFee, effectiveDate) => {
        
        form.setFieldsValue({
            branch: branchName,
            fanshineId: fanshineId,
            fanshineFeeHistoryId: fanshineFeeHistoryId,
            fanshineFee: fanshineFee,
            applicationFee: applicationFee,
            branchFee: branchFee,
            effectiveDate: effectiveDate
        });

        setOpenEditModal(true)
    }

    const editFanshine = async (values) => {
        try {
            const response = await axios.post(`${API_URL}editFanshine`, {
                fanshineId: values.fanshineId,
                fanshineFeeHistoryId: values.fanshineFeeHistoryId,
                fanshineFee: values.fanshineFee,
                applicationFee: values.applicationFee,
                branchFee: values.branchFee,
                effectiveDate: values.effectiveDate
            }, {
                headers: {
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            })

            if (response.status === 200) {
                messageApi.open({
                    type: 'success',
                    content: 'แก้ไขข้อมูลแฟรนไชส์เรียบร้อย',
                });
                fetchData()
                getBranch()
            }
        } catch (error) {
            console.log(error);
        }
    }

    const deleteFanshineShow = async (fanshineId, branchName) => {
        setMessageConfirmDelete(`ยืนยันการลบแฟรนไชส์ ${branchName}?`)
        setFanshineSelect(fanshineId)
        console.log(fanshineId)
        setOpenDeleteModal(true)
    }

    const deleteFanshine = async () => {
        try {
            const response = await axios.delete(`${API_URL}deleteFanshine/${fanshineSelect}`, {
              headers: {
                'Authorization': `bearer ${token}`,
                'Content-Type': 'application/json'
              }
            })
      
            if(response.status === 200){
                messageApi.open({
                    type: 'success',
                    content: 'ลบแฟรนไชส์เรียบร้อย',
                });
                fetchData()
                getBranch()
            }

          } catch (error) {
            console.log('error', error)
          }
    }

  return (
    <div className="fetch-event-table">
      {contextHolder}
      {
        <Modal
            title={
                <>
                    <ExclamationCircleOutlined style={{ color: 'red', marginRight: '8px' }} />
                    ลบแฟรนไชส์
                </>
            }
            centered
            open={openDeleteModal}
            okText="ยืนยัน"
            cancelText="ยกเลิก"
            onOk={() => {
                deleteFanshine()
                setOpenDeleteModal(false)
            }}
            onCancel={() => {
                setOpenDeleteModal(false);
            }}
            width={300}
        >
            {messageConfirmDelete}
        </Modal>
      }
      {
        <Modal
        title="แก้ไขสาขาแฟรนไชส์"
        centered
        open={openEditModal}
        okText="ยืนยัน"
        cancelText="ยกเลิก"
        onOk={() => {
            form
                .validateFields()
                .then((values) => {
                    editFanshine(values)
                    form.resetFields();
                    setOpenEditModal(false);
                })
                .catch((errorInfo) => {
                    console.log('Failed:', errorInfo);
                });
        }}
        onCancel={() => {
            form.resetFields();
            setOpenEditModal(false);
        }}
        width={400}
    >
        <Form
            form={form}
            layout="vertical"
        >
            <Form.Item name="fanshineId" hidden={true}>
                <Input type='hidden'/>
            </Form.Item>
            <Form.Item name="fanshineFeeHistoryId" hidden={true}>
                <Input type='hidden'/>
            </Form.Item>
            <Form.Item name="branch" label="สาขา"
                rules={[
                {
                    required: true,
                    message: 'กรุณาเลือกสาขา',
                }]} >
                <Select
                    allowClear
                    disabled={true}
                    optionFilterProp="label">
                        {branch ? branch.map(branchItem => (
                                <Select.Option key={branchItem.id} value={branchItem.id} label={branchItem.name}>{branchItem.name}</Select.Option>
                         )) : ''}
                </Select>
            </Form.Item>
            <Form.Item name="fanshineFee" label="ค่าบริการแฟรนไชส์ (%)"
                rules={[
                    {
                        required: true,
                        message: 'กรุณากรอกค่าบริการแฟรนไชส์',
                    },
                ]}>
                <Input type='number' min={0} max={100} step={0.5}/>
            </Form.Item>
            <Form.Item name="applicationFee" label="ค่าบริการแอปพลิเคชั่น (%)"
                rules={[
                    {
                        required: true,
                        message: 'กรุณากรอกค่าบริการแอปพลิเคชั่น',
                    },
                ]}>
                <Input type='number' min={0} max={100} step={0.5} />
            </Form.Item>
            <Form.Item name="branchFee" label="ค่าบริการสาขา (%)"
                rules={[
                    {
                        required: true,
                        message: 'กรุณากรอกค่าบริการสาขา',
                    },
                ]}>
                <Input type='number' min={0} max={100} step={0.5} />
            </Form.Item>
            <Form.Item name="effectiveDate" label="วันที่เริ่มเก็บค่าบริการ"
                        rules={[
                            {
                                required: true,
                                message: 'กรุณากรอกวันที่เริ่มเก็บค่าบริการ',
                            },
                        ]}>
                <DatePicker/>
              </Form.Item>
        </Form>
        </Modal>
      }

      {data && data.length > 0 ? (
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          scroll={{
            x: 400,
          }}
          rowKey="id"
        />
      ) : ( emptyBranch ? <p>ไม่พบข้อมูล</p> : <Spin />)
      }
    </div>
  )
}

export default ListTransaction