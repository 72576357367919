import React, { useContext, useEffect, useState } from "react";

import MenuSide from "../components/menuSide";
import WithdrawIncome from "../components/withdrawIncome"
import { Layout, theme, Tabs, Typography, Row, Col, Button } from "antd";
import { AppContext } from '../App';

import {MenuOutlined } from '@ant-design/icons';


const { Title } = Typography;
const { Content } = Layout;

const Wallet = () => {
  
  const { user } = useContext(AppContext);
  if(user){ 
    if(user.role !== 'owner'){      
      window.location.href = "/home"; 
    }
  }

  const [width, setWidth] = useState(window.innerWidth);
  const [menuDisplay, setMenuDisplay] = useState(false);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleMenu = (value) => {
      setMenuDisplay(value)
  }


    return (
      width < 1000 ?
      <Layout className="fullHeight">
        {menuDisplay ? <MenuSide/> : <MenuOutlined onClick={()=>handleMenu(true)} style={{margin:20}}/>}
        <Content className='contentSection' onClick={()=>handleMenu(false)}>
            <Row>
                <Col span={22}><WithdrawIncome/></Col>
            </Row>
          </Content>
      </Layout>
      :
      <Layout className="fullHeight">
          <MenuSide />
          <Content className='contentSection'>
            <Row>
                <Col span={1}></Col>
                <Col span={22}><WithdrawIncome/></Col>
                <Col span={1}></Col>

            </Row>
          </Content>
      </Layout>
    )
  };
  
  export default Wallet;
  