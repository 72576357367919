import React, { useState, useEffect, useContext, useRef } from 'react'
import axios from 'axios'
import { Table, Input, Space, Button } from 'antd'
import { SearchOutlined } from '@ant-design/icons';
import EditUserButton from './editUserButton';
import DeleteUserButton from './deleteUserButton';
import { AppContext } from '../App';
import { configConsumerProps } from 'antd/es/config-provider';

export var fetchData = "";

const FetchUser = () => {
  const { user } = useContext(AppContext);

  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem('Authentication');

  const [data, setData] = useState([])
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: Math.floor(window.screen.height/125),
  });

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
      clearFilters();
      setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({

      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
          style={{
          padding: 8,
          }}
          onKeyDown={(e) => e.stopPropagation()}
      >
          <Input
          ref={searchInput}
          placeholder={`Search User`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
              marginBottom: 8,
              display: 'block',
          }}
          />
          <Space>
          <Button
              type="primary"
              icon={<SearchOutlined />}
              size="small"
              style={{
              width: 120,
              }}
              onClick={() => {
                  confirm({
                      closeDropdown: false,
                  });
                  setSearchText(selectedKeys[0]);
                  setSearchedColumn(dataIndex);
              }}
          >
              Search
          </Button>
          <Button
              onClick={() => {
                  clearFilters
                  handleReset(clearFilters)
                  confirm({
                      closeDropdown: false,
                  });
                  setSearchText(selectedKeys[0]);
                  setSearchedColumn(dataIndex);
              }}
              size="small"
              style={{
              width: 50,
              }}
          >
              Reset
          </Button>
          </Space>
      </div>
      ),
      filterIcon: (filtered) => (
      <SearchOutlined
          style={{
          color: filtered ? '#000000' : undefined,
          }}
      />
      ),
      onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
      onFilterDropdownOpenChange: (visible) => {
      if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
      }
      },
  });
  
  const columns = [
    {
      title: 'ชื่อผู้ใช้',
      width: 300,
      dataIndex: 'username',
      key: 'username',
      // fixed: 'left',
      ...getColumnSearchProps('username'),
      render: (text, record) => (
        <div style={{ fontWeight: 600, color: "#3a9bdc" }}>
          {text}
        </div>
      )    
    },
    {
      title: 'สิทธิ',
      width: 90,
      dataIndex: 'role',
      key: 'role',
      // fixed: 'left',
      render: (text, record) => (
        <div>
          {text}
        </div>
      ),
    },{
      title: 'สาขา',
      width: 90,
      dataIndex: 'branchName',
      key: 'branchName',
      render: (branchName, record) => (
        <div>
          {branchName}
        </div>
      ),
    },
    {
      title: 'การจัดการ',
      key: 'action',
      // fixed: 'right',
      width: 70,
      render: (text, record) => (
        <div>
          <EditUserButton username={record.username} branchList={record.branchList} />
          { user?.username != record.username && (<DeleteUserButton username={record.username} />)}
        </div>
      ),
    },
  ]

  fetchData = async () => {
    try {
      const response = await axios.get(`${API_URL}getListUser`, {
        headers: {
          'Authorization': `bearer ${token}`,
          'Content-Type': 'application/json'
        },
        params: {
          page: pagination.current,
          pageSize: pagination.pageSize,
        },
      })

      const adjustedData = await response.data.map((user) => ({
        ...user,
        key: user.id,
      }));

      for(let element of adjustedData){
        var index = 0
        for(let elementListBranch of element.branchs){
          if(index === 0){ 
            element.branchName = elementListBranch.branchName
            element.branchList = []
            element.branchList.push(elementListBranch.branchId)
          }else if(index > 0){ 
            element.branchName = element.branchName.concat(", ",elementListBranch.branchName) 
            element.branchList.push(elementListBranch.branchId)
          }
          index++;
        }
      }

      setData(adjustedData)
      setPagination((prevPagination) => {
        return {
          ...prevPagination,
          total: response.total,
          pageSize: Math.floor(window.screen.height / 125),
        };
      });
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
        fetchData()
  },[])

  return (
    <div className="fetch-event-table">
      {data && data.length > 0 ? (
        <Table
          columns={columns}
          dataSource={data}
          pagination={pagination}
          onChange={(newPagination) => setPagination(newPagination)}
          scroll={{
            x: 400,
          }}
          rowKey="id"
        />
      ) : (
        <p>Loading</p>
      )}
    </div>
  )
}

export default FetchUser