import React, { useState, useEffect, useContext, useRef } from 'react'
import axios from 'axios'
import { Table, Image, Input, Space, Button, message, Tag, Empty, Typography, Form, Select, Row, Col, Modal, InputNumber} from 'antd'
import { LoadingOutlined } from '@ant-design/icons';
import moment from "moment";
import {SelectOutlined} from "@ant-design/icons"


import { AppContext } from '../App';
const { Title } = Typography;


const WithdrawIncome = () => {
  const { user } = useContext(AppContext);

  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem('Authentication');

  const [data, setData] = useState([])
  const [notEmptyBranch, setNotEmptyBranch] = useState(true) 

  const [summaryIncome,setSummaryIncome] = useState([])

  const [form] = Form.useForm();
  const [formWithdraw] = Form.useForm();
  const [formBranch] = Form.useForm();
  
  const [withdrawActive, setWithdrawActive] = useState('primary')
  const [incomeActive, setIncomeActive] = useState('')

  const [openModalWithdraw,setOpenModalWithdraw] = useState(false)
  const [value, setValue] = useState('0');

  const [branch, setBranch] = useState([]);
  const [branchName, setBranchName] = useState('')

  const toDate = new Date();
  const currentYear = toDate.getFullYear();
  const [yearSelected, setYearSelected] = useState(toDate.getFullYear())
  const [branchSelected, setBranchSelected] = useState('')
  const [listYear, setListYear] = useState([])

  const [withdrawList, setWithdrawList] = useState([])

  const [attachFile, setAttachFile] = useState([])
  const [openAttachFile, setOpenAttachFile] = useState(false)

  const [reason, setReason] = useState('ไม่ระบุ')
  const [openReason,setOpenReason] = useState(false)

  const [withdrawFee,setWithdrawFee] = useState(0)
  const [disableInputWithdraw, setDisableWithdraw] = useState(true)
  const [remainingฺBalance, setRemainBalance] = useState(0)
  const [withdrawAmount, setWithdrawAmount] = useState(0)
  const [fanshieWithdrawButton,setFanshieWithdrawButton] = useState(false)

  const [messageApi, contextHolder] = message.useMessage();

  const [disabledBranch, setDisabledBranch] = useState(false)

  const options = { 
    minimumFractionDigits: 2,
    maximumFractionDigits: 2 
  };

  const columns = [

    {
        title: 'เดือน',
        width: 100,
        dataIndex: 'monthName',
        key: 'monthName',
        fixed: 'left',
        render: (text, record) => (
          <div>
            {text}
          </div>
        )    
    },
    {
      title: 'รายได้ทั้งหมด',
      width: 100,
      dataIndex: 'wallet',
      key: 'wallet',
      fixed: 'left',
      render: (text, record) => (
        <div>
          {record.application ? (record.application + record.coin).toLocaleString('en-US') : 0}
        </div>
      )    
    },
    {
        title: 'รายได้จากหยอดเหรียญ',
        width: 90,
        dataIndex: 'coin',
        key: 'coin',
        render: (text, record) => (
          <div>
            {text ? (text).toLocaleString('en-US') : 0}
          </div>
        ),
    },
    {
        title: 'รายได้จากแอปพลิเคชั่น',
        width: 80,
        dataIndex: 'application',
        key: 'application',
        render: (text, record) => (
          <div>
            {text ? (text).toLocaleString('en-US') : 0}
          </div>
        ),
    },
    {
      title: 'Service & Marketing Fee',
      width: 80,
      dataIndex: 'totalFee',
      key: 'totalFee',
      render: (text, record) => (
        <div style={{color:'orange',fontWeight:'bold'}}>
          {text ? (text).toLocaleString('en',options) : 0}
        </div>
      ),
    },
    {
      title: 'ยอดเงินที่เบิกได้',
      width: 80,
      dataIndex: 'amountCanWithdraw',
      key: 'amountCanWithdraw',
      render: (text, record) => (
        <div style={{color:'green',fontWeight:'bold'}}>
          {text ? (text).toLocaleString('en',options) : 0}
        </div>
      ),
    }
  ]

  const columnsWithdraw = [

    {
        title: 'หมายเลขเอกสาร',
        width: 100,
        dataIndex: 'documentName',
        key: 'documentName',
        fixed: 'left',
        render: (text, record) => (
          <div>
            {text}
          </div>
        )    
    },    {
      title: 'สาขา',
      width: 100,
      dataIndex: 'documentName',
      key: 'documentName',
      fixed: 'left',
      render: (text, record) => (
        <div>
          {record.branch.name}
        </div>
      )    
    },
    {
      title: 'ยอดเงินที่เบิก',
      width: 100,
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      fixed: 'left',
      render: (text, record) => (
        <div>
          {text ? (parseFloat(text)).toLocaleString('en',options) : 0}
        </div>
      )    
    },
    {
        title: 'วันที่เบิก',
        width: 90,
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text, record) => (
          <div>
            {text ? moment(text).format('DD/MM/YYYY') : ''}
          </div>
        ),
    },
    {
        title: 'สถานะ',
        width: 80,
        dataIndex: 'status',
        key: 'status',
        render: (text, record) => (
          <div>
            {text === 'approve' ? <b style={{color:'green'}}>อนุมัติ</b> : (text === 'pending' ? <b style={{color:'orange'}}>รอดำเนินการ</b>  : <b style={{color:'red'}}>ปฏิเสธ</b> )}
          </div>
        ),
    },
    {
      title: 'วันที่อนุมัติ',
      width: 80,
      dataIndex: 'approvedAt',
      key: 'approvedAt',
      render: (text, record) => (
        <div>
            {text ? moment(text).format('DD/MM/YYYY') : ''}
        </div>
      ),
    },
    {
      title: 'หลักฐานการโอนเงิน',
      width: 80,
      dataIndex: 'attachFile1',
      key: 'attachFile1',
      render: (text, record) => (
        <div>
          { record.status === 'approve' ? <Button type='primary' onClick={() => showAttachFile(record.attachFile1, record.attachFile2, record.attachFile3)}>ตรวจสอบ</Button> : ''}
          { record.status === 'reject' ? <Button type='primary' onClick={() => showReason(record.comment)}>เหตุผล</Button> : ''}
        </div>
      ),
    }
  ]

  const changeYearSaleReport = async (value) =>{
    setYearSelected(value)
    setData('')
    fetchData(value,branchSelected)
    getSummaryIncome(branchSelected)
  }

  const changeBranch = async (value) => {
    setBranchSelected(value)
    setSummaryIncome([])
    setData('')
    getSummaryIncome(value)
    fetchData(yearSelected,value)

    branch.forEach(element => {
      if(element.id === value){
        setBranchName(element.name)
        if(element.isFanshine){
          setFanshieWithdrawButton(true)
          activeWithdraw()
        }else{
          setFanshieWithdrawButton(false)
          activeIncome()
        }
      }
    });
  }

  const getListYear = async () =>{
    var listYearArr = []
    for(let index = 2023 ; index <= currentYear ; index++){
      listYearArr.push(index)
    }
    setListYear(listYearArr)
  }

  const getBranch = async () => {
    try {
        const response = await axios.get(`${API_URL}getBranchOwner`,
            {
                headers: { 'Authorization': `bearer ${token}` }
            })
     
        if(response.data.length !== 0){
          
          setBranchName(response.data[0].name)
          formBranch.setFieldsValue({
            branch: response.data[0]? response.data[0].id : [],
          });

          setBranchSelected(response.data[0].id)
          setBranch(response.data);

          if(response.data[0].isFanshine){
            setFanshieWithdrawButton(true)
          }else{
            activeIncome()
          }
        }

    } catch (error) {
        console.log(error);
    }
  }

  const showAttachFile = async(file1,file2, file3) => {
    var listFile = []
    if(file1){ listFile.push(file1)}
    if(file2){ listFile.push(file2)}
    if(file3){ listFile.push(file3)}
    setAttachFile(listFile)
    setOpenAttachFile(true)
  }

  const showReason = async(comment) =>{
    setReason(comment)
    setOpenReason(true)

  }

  const activeWithdraw = async () => {
    setWithdrawActive('primary')
    setIncomeActive('')
  }

  const activeIncome = async () => {
    setWithdrawActive('')
    setIncomeActive('primary')
  }

  const fetchData = async (year,branchId) => {
    if(!year){year = ''}
    if(!branchId){branchId = ''}else{ branchId = "/"+branchId }

    try {
      const response = await axios.get(`${API_URL}listReportIncomeAndFee/${year}${branchId}`, {
        headers: {
          'Authorization': `bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })

      const adjustedData = response.data.map((saleReport) => ({
        ...saleReport,
        key: saleReport.monthName,
      }));


      setData(adjustedData)
    } catch (error) {
      console.log('error', error)
    }
  }

  const getSummaryIncome = async (branchId) => {

    setDisabledBranch(true)

    if(!branchId){branchId = ''}
    try {
      const response = await axios.get(`${API_URL}getSummaryIncome/${branchId}`, {
        headers: {
          'Authorization': `bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })

      if(response.data){
          setSummaryIncome(response.data)
          setDisableWithdraw(false)
          setDisabledBranch(false)
      }


    } catch (error) {
      console.log('error', error)
      setDisabledBranch(false)

    }


  }

  const getWithdrawList = async () =>{
    try {
      const response = await axios.get(`${API_URL}getWithdrawList`, {
        headers: {
          'Authorization': `bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })

      if(response.data){
        setWithdrawList(response.data)
      }

    } catch (error) {
      console.log('error', error)
    }
  }

  const getWithdrawFee = async () =>{
    try {
      const response = await axios.get(`${API_URL}getWithdrawFee`, {
        headers: {
          'Authorization': `bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })

      if(response.data){
        setWithdrawFee(response.data)
      }

    } catch (error) {
      console.log('error', error)
    }
  } 

  const modalWithdraw = async (value) => {
    setWithdrawAmount(0)
    setRemainBalance((summaryIncome.amountCanWithdraw - withdrawFee).toLocaleString('en',options))
    setOpenModalWithdraw(value)
  }

  const confirmWithdraw = async (values) => {

    setDisableWithdraw(true)
    try {
      const response = await axios.post(`${API_URL}createFanshineWithdraw`, {
          branchId: branchSelected,
          totalAmount: values.withdrawAmount
      }, {
          headers: {
              'Authorization': `bearer ${token}`,
              'Content-Type': 'application/json'
          }
      })

      if (response.status === 200) {
          if(response.data.result === 'success'){
            messageApi.open({
              type: 'success',
              content: 'ดำเนินการส่งเรื่องเบิกเงินเรียบร้อย',
          });
          }else{
            messageApi.open({
              type: 'error',
              content: 'การเบิกเงินไม่ถูกต้อง กรุณาตรวจสอบยอดเงินอีกครั้ง',
          });
          }

          setSummaryIncome([])
          getSummaryIncome(branchSelected)
          setWithdrawList([])
          getWithdrawList()
      
          form.setFieldsValue({           
             withdrawAmount: 0
          })
      }
  } catch (error) {
      console.log(error);
  }
  }

  const changeWithdrawAmount = async (value) => {

    if(value){
      setWithdrawAmount(value.toLocaleString('en',options))
      setRemainBalance((summaryIncome.amountCanWithdraw - withdrawFee - value).toLocaleString('en',options))  
    }else{
      setWithdrawAmount(0)
    }

  }

  useEffect(() => {
        getListYear()
        getBranch()
        getSummaryIncome()
        fetchData()
        getWithdrawList()
        getWithdrawFee()
  }, [])

  return (
    
    <div className="fetch-event-table">
      {contextHolder}
      {
        <Modal
          title="หลักฐานการโอนเงิน"
          centered
          open={openAttachFile}
          footer={null}
          onCancel={() => {
            setOpenAttachFile(false)
          }}
          width={800} 
        >
          {
            attachFile ? 
            <ul>
            {attachFile.map(attachFileOne => (
              <a href={attachFileOne} target='_blank'><li key={attachFileOne}>{attachFileOne}</li></a>
            ))} 
            </ul>
            : ''
          }
        </Modal>
      }
      {
        <Modal
          title="เหตุผลการปฏิเสธ"
          centered
          open={openReason}
          footer={null}
          onCancel={() => {
            setOpenReason(false)
          }}
          width={500} 
        >
          {
            <p>
              {reason ? reason : 'ไม่ระบุ' }
            </p>
          }
        </Modal>
      }
      {
        <Modal
          title="เบิกเงิน"
          centered
          open={openModalWithdraw}
          okText="ยืนยัน"
          cancelText="ยกเลิก"
          onOk={() => {
            formWithdraw
                  .validateFields()
                  .then((values) => {
                      confirmWithdraw(values)
                      form.resetFields();
                      modalWithdraw(false);
                  })
                  .catch((errorInfo) => {
                      console.log('Failed:', errorInfo);
                  });
          }}
          onCancel={() => {
            formWithdraw.resetFields();
            modalWithdraw(false);
          }}
          width={400} 
        >
          <Form
            form={formWithdraw}
            layout="vertical"
        >
           
            <Form.Item name="withdrawAmount" label="ยอดเงินที่ต้องการเบิก"
                rules={[
                    { 
                      required: true, 
                      message: 'กรุณากรอกยอดเงินให้ถูกต้อง',
                      pattern: new RegExp("(^[0-9.]*$)"),
                    },
                ]}>
                <InputNumber min={0} max={(summaryIncome.amountCanWithdraw - 30)} value={value} style={{ width: '100%' }} disabled={disableInputWithdraw} onChange={changeWithdrawAmount}/>
            </Form.Item>
            <br />
            <div>
              <div className='divFlexSpaceBetween'>
                <div><b>ยอดเงินที่สามารถเบิกได้</b></div>
                <div><b>{summaryIncome.amountCanWithdraw ? (summaryIncome.amountCanWithdraw).toLocaleString('en',options) : 0 } บาท</b></div>
              </div>
              <div className='divFlexSpaceBetween'>
                <div>ยอดเงินที่ต้องการเบิก</div>
                <div>{withdrawAmount} บาท</div>
              </div>
              <div className='divFlexSpaceBetween'>
                <div>ค่าธรรมเนียมการเบิกเงิน</div>
                <div>{(withdrawFee).toLocaleString('en',options)} บาท</div>
              </div>
              <div>
                <hr/>
              </div>
              <div className='divFlexSpaceBetween' style={{fontWeight:700}}>
                <div>ยอดเงินคงเหลือ</div>
                <div>{remainingฺBalance} บาท</div>
                </div>
            </div>
        </Form>
        </Modal>
      }
      <Row>
        <Col span={22} ><Title level={3} style={{textAlign:'center'}}>สาขา: {branchName}</Title></Col>
      </Row>
      <Row>
          <Col span={22}><Title level={3}><SelectOutlined /> เบิกเงิน</Title></Col>
          <Col span={2}><Title level={3}>{summaryIncome.amountCanWithdraw && fanshieWithdrawButton ? <Button type='primary' onClick={()=>modalWithdraw(true)}> เบิกเงิน</Button> : ''}</Title></Col>
      </Row>
      {
        <Form form={formBranch} >
        <Form.Item name="branch" label='สาขา' className='formItemWithNoMargin'>
        <Select style={{width:150}} optionFilterProp="label" onChange={changeBranch} disabled={disabledBranch}>
            {branch.map(branchItem => (
                <Select.Option key={branchItem.id} value={branchItem.id} label={branchItem.name}>{branchItem.name}</Select.Option>
            ))}
        </Select>
        </Form.Item>
        </Form>
      }

      <div>
        <div className='summaryContent'>
        <table className='tableSummaryTotal'>
          <tr key='totalIncome'> <td>รายได้ทั้งหมด:</td><td style={{textAlign:'right', paddingRight:20}}>{summaryIncome.length !== 0 ? summaryIncome.totalIncome.toLocaleString('en',options) : <LoadingOutlined />}</td> <td>บาท</td> </tr>
          <tr key='coinIncome'> <td style={{paddingLeft:20}}> - รายได้จากเหรียญ:</td><td style={{textAlign:'right', paddingRight:20}}>{summaryIncome.length !== 0 ? summaryIncome.coinIncome.toLocaleString('en',options) : <LoadingOutlined />}</td> <td>บาท</td> </tr>
          <tr key='applicationIncome'> <td style={{paddingLeft:20}}> - รายได้จากแอปพลิเคชัน:</td><td style={{textAlign:'right', paddingRight:20}}>{summaryIncome.length !== 0 ? summaryIncome.applicationIncome.toLocaleString('en',options) : <LoadingOutlined />}</td> <td>บาท</td> </tr>
          <tr key='hr1'> <td colSpan={3}><hr /></td> </tr>
          <tr key='totalFee'> <td>Service & Marketing Fee:</td><td style={{color:'#FF5733',fontWeight:'bold',textAlign:'right', paddingRight:20}}>{summaryIncome.length !== 0 ? summaryIncome.totalFee.toLocaleString('en',options) : <LoadingOutlined />}</td> <td>บาท</td> </tr>
          <tr key='totalWithdraw'> <td>ค่าธรรมเนียมการเบิกเงิน:</td><td style={{color:'#0047AB',fontWeight:'bold',textAlign:'right', paddingRight:20}}>{summaryIncome.length !== 0 ? summaryIncome.withdrawFeeSummary.toLocaleString('en',options) : <LoadingOutlined />}</td> <td>บาท</td> </tr>
          <tr key='amountAlreadyWithdraw'> <td>ยอดเงินที่เบิกไปแล้ว:</td><td style={{color:'#800080',fontWeight:'bold',textAlign:'right', paddingRight:20}}>{summaryIncome.length !== 0 ? summaryIncome.totalWithdraw.toLocaleString('en',options) : <LoadingOutlined />}</td> <td>บาท</td> </tr>
          <tr key='hr2'> <td colSpan={3}><hr /></td> </tr>
          <tr key='amountCanWithdraw'> <td>ยอดเงินที่สามารถเบิกได้:</td><td style={{color:'#008000',fontWeight:'bold', textAlign:'right', paddingRight:20}}>{summaryIncome.length !== 0 ? summaryIncome.amountCanWithdraw.toLocaleString('en',options): <LoadingOutlined />}</td> <td>บาท</td> </tr>
        </table>
        </div>
        <br />

        <Title level={5}>{fanshieWithdrawButton ? <b><Button type={withdrawActive} onClick={() => activeWithdraw()}>ประวัติการเบิกเงิน</Button> | </b>: '' }<Button type={incomeActive} onClick={ () => activeIncome()}>รายละเอียดรายได้</Button></Title>
      </div>

      { 
        withdrawActive === 'primary'   
        ?  
        <div>
          { withdrawList && withdrawList.length > 0 ? (
              <Table
                  columns={columnsWithdraw}
                  dataSource={withdrawList}
                  pagination={false}
                  scroll={{
                    x: 500,
                  }}
                  rowKey="id"
                />
              ) : ''
          } 
        </div>     
        : 
          <div>
            <Form form={form} >
            <Form.Item name="year" className='formItemWithNoMargin'>
            <Select style={{width:150}} optionFilterProp="label" onChange={changeYearSaleReport} defaultValue={yearSelected}>
                {listYear.map(yearItem => (
                    <Select.Option key={yearItem} value={yearItem} >{yearItem}</Select.Option>
                ))}
            </Select>
            </Form.Item>
            </Form>
            {
              notEmptyBranch ? 
              data && data.length > 0 ? (
                <Table
                  columns={columns}
                  dataSource={data}
                  pagination={false}
                  scroll={{
                    x: 400,
                  }}
                  rowKey="branchCode"
                />
              ) : (
                <p>Loading</p>
              )
              :<p>ไม่พบข้อมูล</p>}
          </div>
          }
  
      
    </div>
  )
}

export default WithdrawIncome