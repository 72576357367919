
import React, { useState, useContext, useEffect } from 'react';
import { Button, Modal, Form, Input, DatePicker, Select, message} from 'antd';
import axios from 'axios';
import { UserAddOutlined } from '@ant-design/icons'

import {fetchData} from './fetchUser'


const API_URL = process.env.REACT_APP_API_URL


const CreateUserButton = () => {
    const [open, setOpen] = useState(false);
    const [branch, setBranch] = useState([])
    const [form] = Form.useForm();
    const token = localStorage.getItem('Authentication');
    const [messageApi, contextHolder] = message.useMessage();


    const onSuccess = () => {
        messageApi.open({
            type: 'success',
            content: 'อัปเดตข้อมูลผู้ใช้สำเร็จ',
        });
    }

    useEffect(() => { getBranch(); }, [])

    const getBranch = async () => {
        try {
            const response = await axios.get(`${API_URL}branchListSelect`,
                {
                    headers: { 'Authorization': `bearer ${token}` }
                })
            setBranch(response.data);

        } catch (error) {
            console.log(error);
        }
    }

    const createUser = async (values) => {
        try {
            const response = await axios.post(`${API_URL}createUser`,
                {
                    username: values.userName,
                    password: values.password,
                    role: values.role,
                    branch: values.branch
                },
                {
                    headers: {
                        'Authorization': `bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            )
            if (response.status === 200) {
                    onSuccess();
                    fetchData()
                    getBranch()
            } else {
                console.error('Failed to add user:', response.statusText);
            }
        } catch (error) {
            console.log('error', error)
        }
    }

    return (
        <>
            {contextHolder}
            <Button type="primary" onClick={() => setOpen(true)}>
                <UserAddOutlined />
                เพิ่มผู้ใช้ใหม่
            </Button>
            <Modal
                title="Add New User"
                centered
                open={open}
                okText="Add User"
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            createUser(values)
                            form.resetFields();
                            setOpen(false);
                        })
                        .catch((errorInfo) => {
                            console.log('Failed:', errorInfo);
                        });
                }}
                onCancel={() => {
                    form.resetFields();
                    setOpen(false);
                }}
                width={400}
            >
                <Form
                    form={form}
                    layout="vertical"
                >
                    <Form.Item name="userName" label="Username"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your username!',
                            },
                        ]}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        label="รหัสผ่าน"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Password!',
                            },
                        ]}
                    >
                        <Input type="password" />
                    </Form.Item>
                    <Form.Item name="branch" label="สาขา" >
                        <Select
                            mode="multiple"
                            allowClear
                            optionFilterProp="label">
                            {branch.map(branchItem => (
                                <Select.Option key={branchItem.id} value={branchItem.id} label={branchItem.name}>{branchItem.name}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="role" label="สิทธิผู้ใช้"
                        rules={[
                            {
                                required: true,
                                message: 'Please select your role!',
                            },
                        ]}
                    >
                        <Select>
                            <Select.Option value={'admin'}>ผู้ดูแลระบบ</Select.Option>
                            <Select.Option value={'owner'}>เจ้าของ</Select.Option>
                            <Select.Option value={'manager'}>ผู้ดูแลสาขา</Select.Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};
export default CreateUserButton;