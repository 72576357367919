import React, { useContext, useEffect, useState } from "react";

import MenuSide from "../components/menuSide";
import FetchUser from "../components/fetchUser"
import CreateUserButton from "../components/createUserButton"
import { Layout, theme, Tabs, Typography, Row, Col } from "antd";
import { AppContext } from '../App';
import { MenuOutlined  } from '@ant-design/icons';

const { Title } = Typography;
const { Content } = Layout;

const User = () => {
  
  const { user } = useContext(AppContext);
  if(user){ 
    if(user.role !== 'admin'){      
      window.location.href = "/home"; 
    }
  }

  const [width, setWidth] = useState(window.innerWidth);
  const [menuDisplay, setMenuDisplay] = useState(false);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

    const handleMenu = (value) => {
      setMenuDisplay(value)
    }


    return (
      width < 1000 ?
      <Layout className="fullHeight">
          {menuDisplay ? <MenuSide/> : <MenuOutlined onClick={()=>handleMenu(true)} style={{margin:20}}/>}
          <Content className="contentSection" onClick={()=>handleMenu(false)}>
          <Row>
                <Col span={12}><Title level={3}>ผู้ใช้</Title></Col>
                <Col span={12} className="rigthContent"><CreateUserButton /></Col>
            </Row>
            <FetchUser />
          </Content>
      </Layout>
      :
      <Layout className="fullHeight">
          <MenuSide />
          <Content className='contentSection'>
          <Row>
                <Col span={12}><Title level={3}>ผู้ใช้</Title></Col>
                <Col span={12} className="rigthContent"><CreateUserButton /></Col>
            </Row>
            <FetchUser />
          </Content>
      </Layout>
    )
  };
  
  export default User;
  