import React, { useContext, useEffect, useState } from "react";
import axios from 'axios';

import { Layout, theme, Tabs, Typography, Row, Col, Space, Image, Spin } from "antd";
import { ShopOutlined, MenuOutlined  } from '@ant-design/icons';
import { useParams } from 'react-router-dom';

import MenuSide from "../components/menuSide";
import SaleReportThisBranch from "../components/saleReportThisBranch"

import { AppContext } from '../App';

const { Header, Content } = Layout;
const { Title } = Typography;


const SaleReportBranch = () => {

    const API_URL = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem('Authentication');

    const { code } = useParams()
    const [branchData, setBranchData] = useState('')

    const [width, setWidth] = useState(window.innerWidth);
    const [menuDisplay, setMenuDisplay] = useState(false);
  
    useEffect(() => {
      const handleResize = () => setWidth(window.innerWidth);
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);
  
    const handleMenu = (value) => {
      setMenuDisplay(value)
    }


  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_URL}branchDetailSale/${code}`, {
        headers: {
          'Authorization': `bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })

    setBranchData(response.data)

    } catch (error) {
      console.log('error', error)
    }
  }


    useEffect(() => {
        if(branchData.length === 0 ){
            fetchData()
        }
      }, [branchData])
  
    return (

      width < 1000 ?

      <Layout className="fullHeight">
      {menuDisplay ? <MenuSide/> : <MenuOutlined onClick={()=>handleMenu(true)} style={{margin:20}}/>}
        <Content className="contentSection" onClick={()=>handleMenu(false)}>
          <Row>
                    <Col span={24}>
                      <Title level={3} ><ShopOutlined /> ยอดขายสาขา: {branchData.name}</Title>
                    </Col>

            </Row>
            <p/>
            <Row>
                    <Col span={24}>
                        { branchData.branchImage ? <Image src={branchData.branchImage} /> : <Spin/>}
                    </Col>

                    <Col span={24}>
                        <br /><br />
                        <div className="branchDetail"><b>ชื่อสาขา:</b> {branchData.name}</div>
                        <div className="branchDetail"><b>รหัสสาขา:</b> {branchData.code}</div>
                        <div className="branchDetail"><b>ที่ตั้ง:</b> {branchData.address}</div>
                        <div className="branchDetail"><b>จำนวนเครื่อง:</b> { 
                                branchData.listMachine ? branchData.listMachine.map(machineTypeOne => (
                                     <div key={machineTypeOne.type}> {machineTypeOne.type === 'washer' ? 'เครื่องซัก' : (machineTypeOne.type === 'dryer' ? 'เครื่องอบ' : 'เครื่องซักอบ') }: {machineTypeOne.count} เครื่อง</div>
                                )) : ''
                        }</div>
                    </Col>
            </Row>
            <Row>
                    <Col span={24}>
                    <Title level={4} >ยอดแต่ละเดือน</Title>
                      { code ? <SaleReportThisBranch brachCode={code}/> : <Spin/> }
                    </Col>

            </Row>
          </Content>
          
      </Layout>

      :
      <Layout className="fullHeight">
          <MenuSide />
          <Content className="contentSection">
          <Row>
                    <Col span={1} />
                    <Col span={22}>
                      <Title level={3} ><ShopOutlined /> ยอดขายสาขา: {branchData.name}</Title>
                    </Col>
                    <Col span={1} />

            </Row>
            <p/>
            <Row>
                    <Col span={1} />
                    <Col span={5}>
                        { branchData.branchImage ? <Image src={branchData.branchImage} /> : <Spin/>}
                    </Col>
                    <Col span={17}>
                        <div className="branchDetail"><b>ชื่อสาขา:</b> {branchData.name}</div>
                        <div className="branchDetail"><b>รหัสสาขา:</b> {branchData.code}</div>
                        <div className="branchDetail"><b>ที่ตั้ง:</b> {branchData.address}</div>
                        <div className="branchDetail"><b>จำนวนเครื่อง:</b> { 
                                branchData.listMachine ? branchData.listMachine.map(machineTypeOne => (
                                     <div key={machineTypeOne.type}> {machineTypeOne.type === 'washer' ? 'เครื่องซัก' : (machineTypeOne.type === 'dryer' ? 'เครื่องอบ' : 'เครื่องซักอบ') }: {machineTypeOne.count} เครื่อง</div>
                                )) : ''
                        }</div>
                    </Col>
                    <Col span={1} />
            </Row>
            <Row>
                    <Col span={1} />
                    <Col span={22}>
                    <Title level={4} >ยอดแต่ละเดือน</Title>
                      { code ? <SaleReportThisBranch brachCode={code}/> : <Spin/> }
                    </Col>
                    <Col span={1} />

            </Row>
          </Content>
          
      </Layout>
    )
  };
  
  export default SaleReportBranch;
  