import React, { useContext, useEffect, useState } from "react";
import { useParams } from 'react-router-dom';

import MenuSide from "../components/menuSide";
import ListPointWalletTransaction from "../components/pointWalletTransaction"
import { Layout, theme, Tabs, Typography, Row, Col } from "antd";
import {CopyrightCircleOutlined} from "@ant-design/icons"
import { AppContext } from '../App';
import { MenuOutlined  } from '@ant-design/icons';


const { Title } = Typography;
const { Content } = Layout;

const Wallet = () => {
  
  const { user } = useContext(AppContext);
  if(user){ 
    if(user.role !== 'admin'){      
      window.location.href = "/home"; 
    }
  }

  const [width, setWidth] = useState(window.innerWidth);
  const [menuDisplay, setMenuDisplay] = useState(false);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

    const handleMenu = (value) => {
      setMenuDisplay(value)
    }


    const { phone } = useParams()

    return (
      width < 1000 ?
      <Layout className="fullHeight">
        {menuDisplay ? <MenuSide/> : <MenuOutlined onClick={()=>handleMenu(true)} style={{margin:20}}/>}
        <Content className="contentSection" onClick={()=>handleMenu(false)}>
          <Row>
                <Col span={24}><Title level={3}><CopyrightCircleOutlined /> ประวัติกระเป๋าคะแนน : {phone}</Title></Col>
            </Row>
            <Row>
                <Col span={24}><ListPointWalletTransaction phone={phone}/></Col>
            </Row>
          </Content>
      </Layout>
      :
      <Layout className="fullHeight">
          <MenuSide />
          <Content className='contentSection'>
          <Row>
                <Col span={1}></Col>
                <Col span={22}><Title level={3}><CopyrightCircleOutlined /> ประวัติกระเป๋าคะแนน : {phone}</Title></Col>
                <Col span={1}></Col>
            </Row>
            <Row>
                <Col span={1}></Col>
                <Col span={22}><ListPointWalletTransaction phone={phone}/></Col>
                <Col span={1}></Col>
            </Row>
          </Content>
      </Layout>
    )
  };
  
  export default Wallet;
  