import React from 'react'
import { Image } from 'antd'
import logo from '../images/origami-icon.png'
import collapsedLogo from '../images/origami-icon.png'

const OrigamiLogo = ({ collapsed }) => (
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' , margin: '10px 0px' }}>
    <Image
      maxheight={100}
      width={100}
      preview={false}
      src={collapsed ? collapsedLogo : logo}
    />
  </div>
);
export default OrigamiLogo