import React, { useContext, useEffect, useState } from "react";

import { Layout} from "antd";

import MenuSide from "../components/menuSide";
import ListLaundry from "../components/listLaundry"
import { MenuOutlined  } from '@ant-design/icons';

import { AppContext } from '../App';

const { Header, Content } = Layout;

const Transaction = () => {

  const [width, setWidth] = useState(window.innerWidth);
  const [menuDisplay, setMenuDisplay] = useState(false);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

    const handleMenu = (value) => {
      setMenuDisplay(value)
    }


    return (
      width < 1000 ?
        <Layout className="fullHeight">
            {menuDisplay ? <MenuSide/> : <MenuOutlined onClick={()=>handleMenu(true)} style={{margin:20}}/>}
          <Content className="contentSection" onClick={()=>handleMenu(false)}>
            <ListLaundry />
          </Content>
        </Layout>
      :
        <Layout className="fullHeight">
          <MenuSide />
          <Content className="contentSection">
            <ListLaundry />
          </Content>
        </Layout>
    )
  };
  
  export default Transaction;
  