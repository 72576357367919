import React, { useState, useEffect, useContext, useRef } from 'react'
import axios from 'axios'

import { Card , Row, Col, Form, Select, Spin} from 'antd'
import { AppContext } from '../App';


const IncomeToday = (homeData) => {

    const token = localStorage.getItem('Authentication');
    const API_URL = process.env.REACT_APP_API_URL;

    const [branch, setBranch] = useState([]);
    const [form] = Form.useForm();

    return (
            <Card title="รายได้วันนี้" className='classIncomeToday'>
            { homeData.incomeThisDay.length !== 0 ? (
                <div>
                    <Row className='rowIncomeToday'>
                        <Col span={17}>กระเป๋าเงิน:</Col>
                        <Col span={7}>{homeData.incomeThisDay.wallet ? (homeData.incomeThisDay.wallet).toLocaleString('en-US') : 0}</Col>
                    </Row>
                    <Row className='rowIncomeToday'>
                        <Col span={17}>พร้อมเพย์:</Col>
                        <Col>{homeData.incomeThisDay.promptpay ? (homeData.incomeThisDay.promptpay).toLocaleString('en-US') : 0}</Col>
                    </Row>
                    <Row className='rowIncomeToday'>
                        <Col span={17}>ทรูมันนี่:</Col>
                        <Col>{homeData.incomeThisDay.truemoney ? (homeData.incomeThisDay.truemoney).toLocaleString('en-US') : 0}</Col>
                    </Row>
                    <Row className='rowIncomeToday'>
                        <Col span={17}>หยอดเหรียญ:</Col>
                        <Col>{homeData.incomeThisDay.coin ? (homeData.incomeThisDay.coin).toLocaleString('en-US') : 0}</Col>
                    </Row>
                    <hr className='hrGrey'/>
                    <Row className='rowIncomeToday'>
                        <Col span={17}>รวม:</Col>
                        <Col>{homeData.incomeThisDay.totalIncome ? (homeData.incomeThisDay.totalIncome).toLocaleString('en-US') : 0} บาท</Col>
                    </Row>
                </div>
                )
                : <center><Spin /></center> }
        </Card> 
    )
}

export default IncomeToday