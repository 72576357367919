
import React, { useState, useContext, useEffect } from 'react';
import { Button, Modal, Form, Input, DatePicker, Select, message} from 'antd';
import axios from 'axios';
import { ShopOutlined } from '@ant-design/icons'
import { fetchData } from './listFanshine';

const API_URL = process.env.REACT_APP_API_URL

export var getBranch = ''

const AddFanshine = () => {
    const [open, setOpen] = useState(false);
    const [branch, setBranch] = useState([])
    const [form] = Form.useForm();
    const token = localStorage.getItem('Authentication');
    const [messageApi, contextHolder] = message.useMessage();


    const onSuccess = () => {
        messageApi.open({
            type: 'success',
            content: 'เพิ่มสาขาแฟรนไชส์เรียบร้อย',
        });
    }

    useEffect(() => {
        getBranch();
    },[])

    getBranch = async () => {
        try {
            const response = await axios.get(`${API_URL}getNewBranchFanshine`,
                {
                    headers: { 'Authorization': `bearer ${token}` }
                })
            setBranch(response.data);

        } catch (error) {
            console.log(error);
        }
    }


    const createFanshine = async (values) => {
        setBranch('')
        try {
            const response = await axios.post(`${API_URL}createFanshineBranch`,
                {
                    branch: values.branch,
                    fanshineFee: values.fanshineFee,
                    applicationFee: values.applicationFee,
                    branchFee: values.branchFee,
                    effectiveDate: values.effectiveDate
                },
                {
                    headers: {
                        'Authorization': `bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            )
            if (response.status === 200) {
                    fetchData();
                    onSuccess();
                    getBranch();
            } else {
                console.error('Failed to add user:', response.statusText);
            }
        } catch (error) {
            console.log('error', error)
        }
    }

    return (
        <>
            {contextHolder}
            <Button type="primary" onClick={() => setOpen(true)}>
                <ShopOutlined />
                เพิ่มสาขาแฟรนไชส์
            </Button>
            <Modal
                title="เพิ่มสาขาแฟรนไชส์"
                centered
                open={open}
                okText="ยืนยัน"
                cancelText="ยกเลิก"
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            createFanshine(values)
                            form.resetFields();
                            setOpen(false);
                        })
                        .catch((errorInfo) => {
                            console.log('Failed:', errorInfo);
                        });
                }}
                onCancel={() => {
                    form.resetFields();
                    setOpen(false);
                }}
                width={400}
            >
                <Form
                    form={form}
                    layout="vertical"
                >
                    <Form.Item name="branch" label="สาขา"
                        rules={[
                        {
                            required: true,
                            message: 'กรุณาเลือกสาขา',
                        }]} >
                        <Select
                            allowClear
                            optionFilterProp="label">
                            {branch ? branch.map(branchItem => (
                                <Select.Option key={branchItem.id} value={branchItem.id} label={branchItem.name}>{branchItem.name}</Select.Option>
                            )) : ''}
                        </Select>
                    </Form.Item>
                    <Form.Item name="fanshineFee" label="ค่าบริการแฟรนไชส์ (%)"
                        rules={[
                            {
                                required: true,
                                message: 'กรุณากรอกค่าบริการแฟรนไชส์',
                            },
                        ]}>
                        <Input type='number' min={0} max={100} step={0.5}/>
                    </Form.Item>
                    <Form.Item name="applicationFee" label="ค่าบริการแอปพลิเคชั่น (%)"
                        rules={[
                            {
                                required: true,
                                message: 'กรุณากรอกค่าบริการแอปพลิเคชั่น',
                            },
                        ]}>
                        <Input type='number' min={0} max={100} step={0.5}/>
                    </Form.Item>
                    <Form.Item name="branchFee" label="ค่าบริการสาขา (%)"
                        rules={[
                            {
                                required: true,
                                message: 'กรุณากรอกค่าบริการสาขา',
                            },
                        ]}>
                        <Input type='number' min={0} max={100} step={0.5}/>
                    </Form.Item>
                    <Form.Item name="effectiveDate" label="วันที่เริ่มเก็บค่าบริการ"
                        rules={[
                            {
                                required: true,
                                message: 'กรุณากรอกวันที่เริ่มเก็บค่าบริการ',
                            },
                        ]}>
                        <DatePicker/>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};
export default AddFanshine;