import React, { useState, useEffect, useContext, useRef } from 'react'
import axios from 'axios'
import { Table, Input, Space, Button, message, Tag, Layout, Row, Col, Typography, Form, Modal, DatePicker, TimePicker, Select, Spin, Image, Upload } from 'antd'
import { SearchOutlined,ExclamationCircleOutlined, TagsOutlined, DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import moment from "moment";
import { AppContext } from '../App';
import dayjs from 'dayjs'

const { Header, Content } = Layout;
const { Title } = Typography;
const { RangePicker } = DatePicker;
const { TextArea } = Input;



const ListAccount = () => {
  const { user } = useContext(AppContext);

  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem('Authentication');

  const [data, setData] = useState([])

  const [messageApi, contextHolder] = message.useMessage();

  const [openEditModal, setOpenEditModal] = useState(false);

  const [bankName, setBankName] = useState('')

  const [form] = Form.useForm();

  const bankList = [
    {
        "code": "scb",
        "name": "ธนาคารไทยพาณิชย์"
    },
    {
        "code": "kbank",
        "name": "ธนาคารกสิกรไทย"
    },
    {
        "code": "gsb",
        "name": "ธนาคารออมสิน"
    },
    {
        "code": "ktb",
        "name": "ธนาคารกรุงไทย"
    },
    {
        "code": "ttb",
        "name": "ธนาคารทหารไทยธนชาต"
    },
    {
        "code": "bay",
        "name": "ธนาคารกรุงศรี"
    },
    {
        "code": "uob",
        "name": "ธนาคารยูโอบี"
    },
    {
        "code": "baac",
        "name": "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร"
    },
    {
        "code": "ibank",
        "name": "ธนาคารอิสลามแห่งประเทศไทย"
    },
    {
        "code": "lhb",
        "name": "ธนาคารแลนด์ แอนด์ เฮ้าส์"
    },

    {
        "code": "bbl",
        "name": "ธนาคารกรุงเทพ"
    },
    {
        "code": "citi",
        "name": "ธนาคารซิตี้แบงค์"
    },
    {
        "code": "ghb",
        "name": "ธนาคารอาคารสงเคราะห์"
    },
    {
        "code": "sc",
        "name": "ธนาคาร เดอะรอยัลแบงค์อ๊อฟสกอตแลนด์"
    },
    {
        "code": "smbc",
        "name": "ธนาคารซูมิโตโม มิตซุย แบงค์กิ้ง คอร์ปอเรชั่น"
    },
    {
        "code": "tcrb",
        "name": "ธนาคารไทยเครดิต"
    },
    {
        "code": "tisco",
        "name": "ธนาคารทิสโก้"
    },
    {
        "code": "kk",
        "name": "ธนาคารเกียรตินาคินภัทร"
    },
    {
        "code": "mb",
        "name": "ธนาคารมิซูโฮ"
    },
    {
        "code": "mega",
        "name": "ธนาคารเมกะ สากลพาณิชย์"
    },
    {
        "code": "icbc",
        "name": "ธนาคารไอซีบีซี"
    },
    {
        "code": "bnp",
        "name": "BNP Paribas"
    },
    {
        "code": "boa",
        "name": "Bank of America"
    },
    {
        "code": "cacib",
        "name": "Crédit Agricole"
    },
    {
        "code": "cimb",
        "name": "CIMB Thai Bank"
    },
    {
        "code": "db",
        "name": "Deutsche Bank"
    },
    {
        "code": "hsbc",
        "name": "Hongkong and Shanghai Banking Corporation"
    },
    {
        "code": "jpm",
        "name": "J.P. Morgan"
    },
    {
        "code": "mufg",
        "name": "Bank of Tokyo-Mitsubishi UFJ"
    },
    {
        "code": "rbs",
        "name": "Royal Bank of Scotland"
    },
    
    
]

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_URL}getOwnerBankAccountDetail`, {
        headers: {
          'Authorization': `bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })

      setData(response.data)

      for(let element of bankList){
        if(element.code == response.data.accountBank){
            setBankName(element.name)
        }
      }

    } catch (error) {
      console.log('error', error)
    }
  }
  
  const showEditModal = async () => {

    form.setFieldsValue({
        accountBank: data ? data.accountBank : '',
        accountName: data ? data.accountName : '',
        accountNumber: data ? data.accountNumber : '',
    });

    setOpenEditModal(true)
  }


    const editAccount = async (values) => {

        try {
            const response = await axios.post(`${API_URL}updateOwnerBankAccountDetail`, {
                accountBank: values.accountBank,
                accountName: values.accountName,
                accountNumber: values.accountNumber,
            }, {
                headers: {
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            })
    
            if (response.status === 200) {
                messageApi.open({
                    type: 'success',
                    content: 'แก้ไขข้อมูลบัญชีธนาคารเรียบร้อย',
                });
                fetchData()
            }
        } catch (error) {
            console.log(error);
        }

    }


  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div className="fetch-event-table">
      {contextHolder}
      {
        <Modal
        title="แก้ไขข้อมูลบัญชีธนาคาร"
        centered
        open={openEditModal}
        okText="ยืนยัน"
        cancelText="ยกเลิก"
        onOk={() => {
            form
                .validateFields()
                .then((values) => {
                    editAccount(values)
                    form.resetFields();
                    setOpenEditModal(false);
                })
                .catch((errorInfo) => {
                    console.log('Failed:', errorInfo);
                });
        }}
        onCancel={() => {
            form.resetFields();
            setOpenEditModal(false);
        }}
        width={400}
    >
        <Form
            form={form}
            layout="vertical"
        >

            <Form.Item name="accountBank" label="ธนาคาร"
                rules={[
                    {
                        required: true,
                        message: 'กรุณาเลือกธนาคาร',
                    },
                ]}>
                <Select
                    showSearch
                    optionFilterProp="label"
                >
                    {bankList.map(bankOne => (
                        <Select.Option key={bankOne.code} value={bankOne.code} label={bankOne.name}>{bankOne.name}</Select.Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item name="accountName" label="ชื่อบัญชี"
                        rules={[
                            {
                                required: true,
                                message: 'กรุณากรอกชื่อบัญชี',
                            },
                        ]}>
                <Input type='text'/>
              </Form.Item>
              <Form.Item name="accountNumber" label="เลขที่บัญชี"
                        rules={[
                            {
                                required: true,
                                message: 'กรุณากรอกเลขที่บัญชีให้ถูกต้อง',
                                pattern: new RegExp("(^[0-9]*$)"),

                            },
                        ]}>
                <Input type='text'/>
              </Form.Item>

              
        </Form>
        </Modal>
      }
    
        <Row>
            <Col span={24} >
            <div className='centerSection'>
                <div className='divDetailSection'>
                <table className='bankAccountTable'>
                    <tr key='accountBank'> <td>ธนาคาร:</td><td style={{textAlign:'right', paddingRight:20}}>{bankName ? bankName : ''}</td></tr>
                    <tr key='accountName'> <td>ชื่อบัญชี:</td><td style={{textAlign:'right', paddingRight:20}}>{data ? data.accountName : ''}</td></tr>
                    <tr key='accountNumber'> <td>เลขที่บัญชี:</td><td style={{textAlign:'right', paddingRight:20}}>{data ? data.accountNumber : ''}</td></tr>
                </table>
                <Button onClick={()=>showEditModal()} type='primary' style={{width:'100%'}}>แก้ไข</Button>
                </div>
            </div>
            </Col>
        </Row>
    </div>
  )
}

export default ListAccount