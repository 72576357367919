import React, { useContext, useEffect, useState } from "react";
import axios from 'axios';

import { Layout, theme, Tabs, Typography, Row, Col, Space, Form, Select, Radio } from "antd";
import { HomeFilled } from '@ant-design/icons';

import MenuSide from "../components/menuSide";
import IncomeToday from "../components/incomeToday"
import IncomeThisWeek from "../components/incomeThisWeek"
import IncomePerDay from "../components/incomePerDay.js"
import IncomePerHour from "../components/incomePerHour.js"
import TopSpender from "../components/topSpender.js"
import SpenderRange from "../components/spenderRange.js"
import ListBranchIncome from "../components/listBranchIncome.js"

import { AppContext } from '../App';
import { MenuOutlined  } from '@ant-design/icons';


const { Header, Content } = Layout;
const { Title } = Typography;

const API_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem('Authentication');



const Home = () => {

    const [branch, setBranch] = useState([]);
    const [chartType, setChartType] = useState("week")
    const [form] = Form.useForm();
    const [branchSelected, setBranchSelected] = useState('all')
    const [incomeThisDay, setIncomeThisDay] = useState([])
    const [width, setWidth] = useState(window.innerWidth);
    const [menuDisplay, setMenuDisplay] = useState(false);

    useEffect(() => {
      const handleResize = () => setWidth(window.innerWidth);
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);


    const getBranch = async () => {
      try {
          const response = await axios.get(`${API_URL}getBranchManager`,
              {
                  headers: { 'Authorization': `bearer ${token}` }
              })
          setBranch(response.data); 
      } catch (error) {
          console.log(error);
          if(error.response.status == 401){
            window.location.href = "/"
          }else{
            if(token){ window.location.reload();}
          }  
      }
    }

    const getIncomeToday = async (branchId) => {

      if(!branchId){branchId = ""}
      try {
          const response = await axios.get(`${API_URL}getIncomeToday/${branchId}`, {
            headers: {
              'Authorization': `bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })
          setIncomeThisDay(response.data.incomeToday)

        } catch (error) {
          console.log('error', error)
        }
  }

    const changeBranch = async (value) => {
      setIncomeThisDay('')
      setBranchSelected(value)
      getIncomeToday(value)
    }
    
    const handleChange = (e) => {
      setChartType(e.target.value)
    }

    const handleMenu = (value) => {
      setMenuDisplay(value)
    }

    useEffect(() => {
      getBranch()
      getIncomeToday(branchSelected)
    },[])

    return (
      
        width < 1000 ?
        <Layout className="fullHeight" >
          {menuDisplay ? <MenuSide/> : <MenuOutlined onClick={()=>handleMenu(true)} style={{margin:20}}/>}
          <Content className="contentSection" onClick={()=>handleMenu(false)}>
          <Row>
            <Col span={22}><Title level={2} >Origami insight </Title></Col>
          </Row>

            <Row>
              <Col span={22}>
                  <Form form={form} >
                  <Form.Item name="branch" label="" className='formItemWithNoMargin'>
                    <Select style={{width:250}} optionFilterProp="label" onChange={changeBranch} defaultValue="all">
                        <Select.Option key='all' value='all' label='สาขาทั้งหมด'>สาขาทั้งหมด</Select.Option>
                        {branch.map(branchItem => (
                            <Select.Option key={branchItem.id} value={branchItem.id} label={branchItem.name}>{branchItem.name}</Select.Option>
                        ))}
                    </Select>
                  </Form.Item>  
                  </Form> 
                </Col>
            </Row>
            <Row>
              <Col span={24}>
                <br />
                <Radio.Group
                        options={[
                          { value: 'week', label: '7D' },
                          { value: '30day', label: '30D' },
                          { value: '12month', label: '12M' },
                          { value: 'month', label: 'Month' },
                          { value: 'year', label: 'Year' },
                        ]}              
                        onChange={handleChange}
                        value={chartType}
                        optionType="button"
                        buttonStyle="solid"
                      />
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                  <br />
                  <IncomeToday branchSelected={branchSelected} incomeThisDay={incomeThisDay}/>
              </Col>
            </Row>

            <Row>
                    <Col span={24}>
                      <br />
                      <IncomeThisWeek branchSelectedIndex={branchSelected} incomeThisDay={incomeThisDay}  chartType={chartType}/>
                    </Col>
            </Row>

            <Row>
                    <Col span={24}>
                      <br />
                      <IncomePerDay branchSelectedIndex={branchSelected} incomeThisDay={incomeThisDay}  chartType={chartType}/>
                    </Col>
            </Row>
            <Row>
                    <Col span={24}>
                      <br />
                      <IncomePerHour branchSelectedIndex={branchSelected} incomeThisDay={incomeThisDay}  chartType={chartType}/>     
                    </Col>  
            </Row>
            <Row>
                    <Col span={24}>
                      <br />
                      <TopSpender branchSelectedIndex={branchSelected} incomeThisDay={incomeThisDay}  chartType={chartType}/>
                    </Col>
            </Row>
            <Row>
                    <Col span={24}>
                      <br />
                      <SpenderRange branchSelectedIndex={branchSelected} incomeThisDay={incomeThisDay}  chartType={chartType}/>     
                    </Col>  
            </Row>

            <Row>
                    <Col span={24}>
                        <br />
                        <Title level={4} > รายได้แต่ละสาขา</Title>
                        <ListBranchIncome />
                    </Col>
            </Row>
          </Content>
          
      </Layout>
      
        : 

        <Layout className="fullHeight">
          <MenuSide />
          <Content className="contentSection">
          <Row>
            <Col span={1} />
            <Col span={22}><Title level={2} >Origami insight </Title></Col>
          </Row>

          <Row>
                    <Col span={1} />
                    <Col span={10}></Col>
                    <Col span={12}>

                    <Title level={5} >
                        <div className='optionChartWeekIncome'>
                        <Form form={form} >
                        <Form.Item name="branch" label="" className='formItemWithNoMargin' style={{marginLeft:40}}>
                          <Select style={{width:175}} optionFilterProp="label" onChange={changeBranch} defaultValue="all">
                              <Select.Option key='all' value='all' label='สาขาทั้งหมด'>สาขาทั้งหมด</Select.Option>
                              {branch.map(branchItem => (
                                  <Select.Option key={branchItem.id} value={branchItem.id} label={branchItem.name}>{branchItem.name}</Select.Option>
                              ))}
                          </Select>
                        </Form.Item>  
                        </Form>
                          &nbsp;&nbsp;
                          <Radio.Group
                            options={[
                              { value: 'week', label: '7D' },
                            { value: '30day', label: '30D' },
                            { value: '12month', label: '12M' },
                            { value: 'month', label: 'Month' },
                            { value: 'year', label: 'Year' },
                            ]}              
                            onChange={handleChange}
                            value={chartType}
                            optionType="button"
                            buttonStyle="solid"
                          />
                          </div>  
                    </Title>

                     </Col>

            </Row>
            <Row>
                    <Col span={1} />
                    <Col span={6}>
                        <IncomeToday branchSelected={branchSelected} incomeThisDay={incomeThisDay}/>
                    </Col>
                    <Col span={1} />
                    <Col span={15}>
                        <IncomeThisWeek branchSelectedIndex={branchSelected} incomeThisDay={incomeThisDay}  chartType={chartType}/>
                    </Col>


            </Row>
            <br/><br/>
            <Row>
                    <Col span={1} />
                    <Col span='7'>
                      <IncomePerDay branchSelectedIndex={branchSelected} incomeThisDay={incomeThisDay}  chartType={chartType}/>
                    </Col>
                    <Col span={1} />
                    <Col span='14'>
                      <IncomePerHour branchSelectedIndex={branchSelected} incomeThisDay={incomeThisDay}  chartType={chartType}/>     
                    </Col>  
            </Row>
            <br/><br/>
            <Row>
                    <Col span={1} />
                    <Col span='7'>
                      <TopSpender branchSelectedIndex={branchSelected} incomeThisDay={incomeThisDay}  chartType={chartType}/>
                    </Col>
                    <Col span={1} />
                    <Col span='14'>
                      <SpenderRange branchSelectedIndex={branchSelected} incomeThisDay={incomeThisDay}  chartType={chartType}/>     
                    </Col>  
            </Row>


            <Row>
                    <Col span={1} />
                    <Col span='22'>
                        <Title level={4} > รายได้แต่ละสาขา</Title>
                        <ListBranchIncome />
                    </Col>
                    <Col span={1} />
            </Row>
          </Content>
          
      </Layout>
      
      
    )
  };
  
  export default Home;
  