import React, { useContext, useEffect, useState } from "react";
import { useParams } from 'react-router-dom';

import MenuSide from "../components/menuSide";
import CommissionHistory from "../components/commissionHistory"
import { Layout, theme, Tabs, Typography, Row, Col } from "antd";
import {UserOutlined} from "@ant-design/icons"
import { AppContext } from '../App';
import { TruckOutlined, MenuOutlined  } from '@ant-design/icons';

const { Title } = Typography;
const { Content } = Layout;


const User = () => {

    const { commissionUserId } = useParams();
    const { user } = useContext(AppContext);
    if(user){ 
      if(user.role !== 'admin'){      
        window.location.href = "/home"; 
      }
    }

    const [width, setWidth] = useState(window.innerWidth);
    const [menuDisplay, setMenuDisplay] = useState(false);
  
    useEffect(() => {
      const handleResize = () => setWidth(window.innerWidth);
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);
  
    const handleMenu = (value) => {
      setMenuDisplay(value)
    }
  
  
    return (
      width < 1000 ?
      <Layout className="fullHeight">
          {menuDisplay ? <MenuSide/> : <MenuOutlined onClick={()=>handleMenu(true)} style={{margin:20}}/>}
          <Content className='contentSection'>
            <Row>
                <Col span={24}><CommissionHistory commissionUserId={commissionUserId} /></Col>
            </Row>
          </Content>
      </Layout>
      :
      <Layout className="fullHeight">
          <MenuSide />
          <Content className='contentSection'>
            <Row>
                <Col span={24}><CommissionHistory commissionUserId={commissionUserId} /></Col>
            </Row>
          </Content>
      </Layout>
    )
  };
  
  export default User;
  