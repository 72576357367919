import React,{ createContext, useState, useEffect } from 'react'
import axios from 'axios';
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import Dashboard from "./pages/dashboard";
import User from "./pages/user";
import Login from './pages/login';
import Home from './pages/home'
import Transaction from './pages/transaction'
import Machine from './pages/machine'
import Laundry from './pages/laundry'
import Branchs from './pages/branch'
import SaleReport from './pages/saleReport'
import SaleReportBranch from './pages/saleReportBranch'
import Customer from './pages/customer'
import Wallet from './pages/wallet'
import WalletCustomer from './pages/walletCustomer'
import Point from './pages/point'
import PointWalletCustomer from './pages/pointWalletCustomer'
import Withdraw from './pages/withdraw'
import MachineControlHistory from './pages/machineControlHistory'
import Fanshine from './pages/fanshine'
import FanshineHistoryFee from './pages/fanshineHistoryFee'
import FanshineWithdraw from './pages/fanshineWithdraw'
import Coupon from './pages/coupon'
import Promotion from './pages/promotion'
import PromotionBranch from './pages/promotionBranch'
import News from './pages/news'
import BankAccount from './pages/bankAccount'
import Commission from './pages/commission'
import CommissionHistory from './pages/commissionHistory'
import MachineUsageReport from './pages/machineUsageReport';


import AccountReport from './pages/accountReport'

import "./App.css"

export const AppContext = createContext();

const router = createBrowserRouter([
  {
    path: '/',
    element: <Login />
  },
  {
    path: '/home',
    element: <Home />
  },
  {
    path: '/machine',
    element: <Machine />
  },
  {
    path: '/dashboard',
    element: <Dashboard />
  },
  {
    path: '/transaction',
    element: <Transaction />
  },
  {
    path: '/laundry',
    element: <Laundry />
  },
  {
    path: '/branchs',
    element: <Branchs />
  },
  {
    path: '/saleReport',
    element: <SaleReport />
  },
  {
    path: '/saleReportBranch/:code',
    element: <SaleReportBranch />,
  },
  {
    path: '/user',
    element: <User />
  },
  {
    path: '/customer',
    element: <Customer />
  },
  {
    path: '/wallet',
    element: <Wallet />
  },
  {
    path: '/walletCustomer/:phone',
    element: <WalletCustomer />,
  },
  {
    path: '/point',
    element: <Point />
  },
  {
    path: '/pointWalletCustomer/:phone',
    element: <PointWalletCustomer />
  },
  {
    path: '/coupon',
    element: <Coupon />
  },
  {
    path: '/promotion',
    element: <Promotion />
  },
  {
    path: '/promotionBranch',
    element: <PromotionBranch />
  },
  {
    path: '/news',
    element: <News />
  },
  {
    path: '/accountReport',
    element: <AccountReport />
  },
  {
    path: '/withdraw',
    element: <Withdraw />
  },
  {
    path: '/machineControlHistory',
    element: <MachineControlHistory />
  },
  {
    path: '/machineUsageReport',
    element: <MachineUsageReport />
  },
  {
    path: '/fanshine',
    element: <Fanshine />
  },
  {
    path: '/fanshineFeeHistory/:fanshineId',
    element: <FanshineHistoryFee />
  },
  {
    path: '/withdrawList',
    element: <FanshineWithdraw />
  },
  {
    path: '/bankAccount',
    element: <BankAccount />
  },
  {
    path: '/commission',
    element: <Commission />
  },
  {
    path: '/commissionHistory/:commissionUserId',
    element: <CommissionHistory />
  },
  {
    path: "*",
    element: <Home />
  }
]);


export default function App() {

  const [token, setToken] = useState(localStorage.getItem("Authentication"));
  const [user, setUser] = useState(null);
  const [loggedIn, setLoggedIn] = useState(true);
  const API_URL = process.env.REACT_APP_API_URL;

  const login = async (username, password) => {
    try {
      setLoggedIn(false);
      const response = await axios.post(`${API_URL}login`, {
        username: username,
        password: password,
      });

      localStorage.setItem('Authentication', response.data.jwtToken);
      setToken(localStorage.getItem("Authentication"));

      return true;

    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (token) {
      const fetch = async () => {
        try {
          const response = await axios.get(`${API_URL}getCurrUser`, {
            headers: {
              'Authorization': `bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })

          setUser(response.data.userData);

        } catch (error) {
          if (error.response.data.errorInfo.name === 'TokenExpiredError') {
            alert('token expired');
            logout();
          }
          console.log(error);
        }
      };
      fetch();
    } else {
      router.navigate('/');
    }
  }, [token])

  useEffect(() => {
    if (user && !loggedIn) {
      handleRedirect(user.role);
    }
  }, [user])

  const handleRedirect = (role) => {
    setLoggedIn(true);
    if (role === 'admin') {
      router.navigate('/home');
    } else if (role === 'manager' || role === 'owner') {
      router.navigate('/home');
    } else {
      router.navigate('/');
    }
  }

  const logout = () => {
    localStorage.clear();
    setUser(null);
    router.navigate('/');
  }


  return (
    <React.StrictMode>
      <AppContext.Provider value={{ login, logout, user, handleRedirect }}>
        <RouterProvider router={router} />
      </AppContext.Provider>
    </React.StrictMode>
  );
}

