import React, { useState, useEffect, useRef } from 'react'

import axios from 'axios'
import { Table, Input, Space, Button, message, Modal, Select, Row, Col, Typography, Form} from 'antd'
import { SearchOutlined, TruckOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

const { Title } = Typography;



const FetchCustomer = (commissionUserId) => {

  const customerId = commissionUserId.commissionUserId
  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem('Authentication');

  const [customerData, setCustomerData] = useState([])
  const [data, setData] = useState([])
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: Math.floor(window.screen.height/100),
  });

  const [customerPhone, setCustomerPhone] = useState('');

  const [hasData, setHasData] = useState(false) 
  const [emptyData, setEmptyData] = useState(false)
 
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
      clearFilters();
      setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({

      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
          style={{
          padding: 8,
          }}
          onKeyDown={(e) => e.stopPropagation()}
      >
          <Input
          ref={searchInput}
          placeholder={`Search User`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
              marginBottom: 8,
              display: 'block',
          }}
          />
          <Space>
          <Button
              type="primary"
              icon={<SearchOutlined />}
              size="small"
              style={{
              width: 120,
              }}
              onClick={() => {
                  confirm({
                      closeDropdown: false,
                  });
                  setSearchText(selectedKeys[0]);
                  setSearchedColumn(dataIndex);
              }}
          >
              Search
          </Button>
          <Button
              onClick={() => {
                  clearFilters
                  handleReset(clearFilters)
                  confirm({
                      closeDropdown: false,
                  });
                  setSearchText(selectedKeys[0]);
                  setSearchedColumn(dataIndex);
              }}
              size="small"
              style={{
              width: 50,
              }}
          >
              Reset
          </Button>
          </Space>
      </div>
      ),
      filterIcon: (filtered) => (
      <SearchOutlined
          style={{
          color: filtered ? '#000000' : undefined,
          }}
      />
      ),
      onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
      onFilterDropdownOpenChange: (visible) => {
      if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
      }
      },
  });
  
  const columns = [
    {
      title: 'วันที่',
      width: 70,
      dataIndex: 'id',
      key: 'id',
      render: (text, record) => (
        <div style={{ fontWeight: 600, color: "#3a9bdc" }}>
          {moment(record.laundryHistory.startMachineTime).format('DD/MM/YYYY H:mm')}
        </div>
      )    
    },
    {
        title: 'สาขา',
        width: 90,
        dataIndex: 'laundry',
        key: 'laundry',
        // fixed: 'left',
        render: (text, record) => (
          <div>
            {record.laundryHistory.machine.branch.name}
          </div>
        ),
    },
    {
      title: 'ประเภท',
      width: 90,
      dataIndex: 'machineType',
      key: 'machineType',
      // fixed: 'left',
      render: (text, record) => (
        <div>
          {record.laundryHistory.machineType == "washer" ? "ซักผ้า" : (record.laundryHistory.machineType == "dryer" ? "อบผ้า" : "ซักและอบ" )}
        </div>
      ),
  },
    {
        title: 'หมายเลขเครื่อง',
        width: 50,
        dataIndex: 'laundry',
        key: 'laundry',
        align: 'center',
        render: (text, record) => (
          <div>
            {record.laundryHistory.machine.machineNo}
          </div>
        ),
    },
    {
      title: 'ราคาที่ชำระ (บาท)',
      width: 90,
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      align: 'center',
      render: (text, record) => (
        <div>
          { record.laundryHistory.totalAmount }
        </div>
      ),
    },
    {
      title: 'ส่วนลดคอมมิชชั่น (บาท)',
      width: 90,
      dataIndex: 'commissionValue',
      key: 'commissionValue',
      align: 'center',
      render: (text, record) => (
        <div>
          { text }
        </div>
      ),
    },
  ]

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_URL}getCommissionHistory/${customerId}`, {
        headers: {
          'Authorization': `bearer ${token}`,
          'Content-Type': 'application/json'
        },
        params: {
          page: pagination.current,
          pageSize: pagination.pageSize,
        },
      })

      const adjustedData = await response.data.map((customer) => ({
        ...customer,
        key: customer.id,
      }));


      if(adjustedData.length !== 0){
        setHasData(true)
        setData(adjustedData)
        setCustomerPhone(response.data[0].customer.phone)
  
      }else{
        setEmptyData(true)
      }
            
      setPagination((prevPagination) => {
        return {
          ...prevPagination,
          total: response.total,
          pageSize: Math.floor(window.screen.height / 100),
        };
      });
    } catch (error) {
      console.log('error', error)
    }
  }

 
  useEffect(() => {
    fetchData()
  },[])

  return (
    <div className="fetch-event-table">
      <Row>
        <Col span={1} />
        <Col span={22}>
          <Title level={3}><TruckOutlined /> ประวัติการใช้งาน : {customerPhone}</Title>
        </Col>
        <Col span={1} />

      </Row>
      <Row>
        <Col span={1} />
        <Col span={22}>

        {data && hasData ? (
          <Table
            columns={columns}
            dataSource={data}
            pagination={pagination}
            onChange={(newPagination) => setPagination(newPagination)}
            scroll={{
              x: 400,
            }}
            rowKey="id"
          />
        ) : ( emptyData ? <p>ไม่พบข้อมูล</p> : (
          <p>Loading</p>
        ))}
        </Col>
        <Col span={1} />

      </Row>
    </div>

  )}

export default FetchCustomer