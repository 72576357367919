
import React, { useContext, useEffect, useState } from "react";

import { Layout, Typography,Row, Col} from "antd";

import MenuSide from "../components/menuSide";
import ListPromotionBranch from "../components/ListPromotionBranch"
import { MenuOutlined  } from '@ant-design/icons';


import { AppContext } from '../App';

const { Header, Content } = Layout;
const { Title } = Typography;

const promotionBranch = () => {

  const [width, setWidth] = useState(window.innerWidth);
  const [menuDisplay, setMenuDisplay] = useState(false);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

    const handleMenu = (value) => {
      setMenuDisplay(value)
    }



    return (
      width < 1000 ? 
      <Layout className="fullHeight">
          {menuDisplay ? <MenuSide/> : <MenuOutlined onClick={()=>handleMenu(true)} style={{margin:20}}/>}
          <Content className="contentSection" onClick={()=>handleMenu(false)}>

          <Row>
              <Col span={1} />
              <Col span={22} >
                  <ListPromotionBranch />
              </Col>
              <Col span={1} />
          </Row>
          
        </Content>
        
    </Layout>
      :
      <Layout className="fullHeight">
          <MenuSide />
          <Content className="contentSection">

            <Row>
                <Col span={1} />
                <Col span={22} >
                    <ListPromotionBranch />
                </Col>
                <Col span={1} />
            </Row>
            
          </Content>
          
      </Layout>
    )
  };
  
  export default promotionBranch;
  