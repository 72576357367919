import React, { useState, useEffect, useContext, useRef } from 'react'
import axios from 'axios'
import { Table, Image, Input, Space, Button, message, Tag, Empty, Typography, Form, Select, Row, Col, Modal, InputNumber, Spin, Upload} from 'antd'
import { SearchOutlined, EyeOutlined, UploadOutlined} from '@ant-design/icons';
import moment from "moment";
import {SelectOutlined} from "@ant-design/icons"


import { AppContext } from '../App';
import { configConsumerProps } from 'antd/es/config-provider';
const { Title } = Typography;


const ListFanshineWithdraw = () => {
  const { user } = useContext(AppContext);
  const { TextArea } = Input;


  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem('Authentication');

  const [form] = Form.useForm();
  const [withdrawList, setWithdrawList] = useState([])
  const [emptyList, setEmptyList] = useState(true)

  const [openModalEdit, setOpenModalEdit] = useState(false)

  const [hideComment, setHideComment] = useState(true)
  const [hideAttachFile, setHideAttachFile] = useState(true)

  const [messageApi, contextHolder] = message.useMessage();

  const [openModalQrcode, setOpenModalQrcode] = useState(false)
  const [modalContent, setModalContent] = useState('')

  const [fileUrl1, setFileUrl1] = useState('')
  const [file1, setFile1] = useState([])

  const [fileUrl2, setFileUrl2] = useState('')
  const [file2, setFile2] = useState([])

  const [fileUrl3, setFileUrl3] = useState('')
  const [file3, setFile3] = useState([])



  const bankList = [
    {
        "code": "scb",
        "name": "ธนาคารไทยพาณิชย์"
    },
    {
        "code": "kbank",
        "name": "ธนาคารกสิกรไทย"
    },
    {
        "code": "gsb",
        "name": "ธนาคารออมสิน"
    },
    {
        "code": "ktb",
        "name": "ธนาคารกรุงไทย"
    },
    {
        "code": "ttb",
        "name": "ธนาคารทหารไทยธนชาต"
    },
    {
        "code": "bay",
        "name": "ธนาคารกรุงศรี"
    },
    {
        "code": "uob",
        "name": "ธนาคารยูโอบี"
    },
    {
        "code": "baac",
        "name": "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร"
    },
    {
        "code": "ibank",
        "name": "ธนาคารอิสลามแห่งประเทศไทย"
    },
    {
        "code": "lhb",
        "name": "ธนาคารแลนด์ แอนด์ เฮ้าส์"
    },

    {
        "code": "bbl",
        "name": "ธนาคารกรุงเทพ"
    },
    {
        "code": "citi",
        "name": "ธนาคารซิตี้แบงค์"
    },
    {
        "code": "ghb",
        "name": "ธนาคารอาคารสงเคราะห์"
    },
    {
        "code": "sc",
        "name": "ธนาคาร เดอะรอยัลแบงค์อ๊อฟสกอตแลนด์"
    },
    {
        "code": "smbc",
        "name": "ธนาคารซูมิโตโม มิตซุย แบงค์กิ้ง คอร์ปอเรชั่น"
    },
    {
        "code": "tcrb",
        "name": "ธนาคารไทยเครดิต"
    },
    {
        "code": "tisco",
        "name": "ธนาคารทิสโก้"
    },
    {
        "code": "kk",
        "name": "ธนาคารเกียรตินาคินภัทร"
    },
    {
        "code": "mb",
        "name": "ธนาคารมิซูโฮ"
    },
    {
        "code": "mega",
        "name": "ธนาคารเมกะ สากลพาณิชย์"
    },
    {
        "code": "icbc",
        "name": "ธนาคารไอซีบีซี"
    },
    {
        "code": "bnp",
        "name": "BNP Paribas"
    },
    {
        "code": "boa",
        "name": "Bank of America"
    },
    {
        "code": "cacib",
        "name": "Crédit Agricole"
    },
    {
        "code": "cimb",
        "name": "CIMB Thai Bank"
    },
    {
        "code": "db",
        "name": "Deutsche Bank"
    },
    {
        "code": "hsbc",
        "name": "Hongkong and Shanghai Banking Corporation"
    },
    {
        "code": "jpm",
        "name": "J.P. Morgan"
    },
    {
        "code": "mufg",
        "name": "Bank of Tokyo-Mitsubishi UFJ"
    },
    {
        "code": "rbs",
        "name": "Royal Bank of Scotland"
    },
    
    
]

  const options = { 
    minimumFractionDigits: 2,
    maximumFractionDigits: 2 
  };
  
  const columnsWithdraw = [

    {
        title: 'หมายเลขเอกสาร',
        width: 100,
        dataIndex: 'documentName',
        key: 'documentName',
        render: (text, record) => (
          <div>
            {text}
          </div>
        )    
    },    {
      title: 'สาขา',
      width: 100,
      dataIndex: 'documentName',
      key: 'documentName',
      render: (text, record) => (
        <div>
          {record.branch.name}
        </div>
      )    
    },
    {
      title: 'ยอดเงินที่เบิก',
      width: 100,
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      render: (text, record) => (
        <div>
          {text ? (parseFloat(text)).toLocaleString('en',options) : 0}
        </div>
      )    
    },
    {
      title: 'เจ้าของสาขา',
      width: 90,
      dataIndex: 'userWithdraw',
      key: 'userWithdraw',
      render: (text, record) => (
        <div>
          {record.user ? record.user.name : ''}
        </div>
      ),
    },
    {
        title: 'วันที่เบิก',
        width: 90,
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text, record) => (
          <div>
            {text ? moment(text).format('DD/MM/YYYY') : ''}
          </div>
        ),
    },
    {
        title: 'สถานะ',
        width: 80,
        dataIndex: 'status',
        key: 'status',
        render: (text, record) => (
          <div>
            {text === 'approve' ? <b style={{color:'green'}}>อนุมัติ</b> : (text === 'pending' ? <b style={{color:'orange'}}>รอดำเนินการ</b>  : <b style={{color:'red'}}>ปฏิเสธ</b> )}
          </div>
        ),
    },
    // {
    //   title: 'วันที่อนุมัติ',
    //   width: 80,
    //   dataIndex: 'approvedAt',
    //   key: 'approvedAt',
    //   render: (text, record) => (
    //     <div>
    //         {text ? moment(text).format('DD/MM/YYYY') : ''}
    //     </div>
    //   ),
    // },
    {
      title: 'หลักฐานการโอนเงิน',
      width: 80,
      dataIndex: 'attachFile1',
      key: 'attachFile1',
      render: (text, record) => (
        <div>
          <Button onClick={()=> viewWithdrawDetail(record)}><EyeOutlined /></Button> <Button type='primary' onClick={() => showEditWithdraw(record)}>จัดการ</Button>
        </div>
      ),
    }
  ]

  const viewWithdrawDetail = async(record) => {

    if(record.user.ownerBankAccount){
      var bankName = ''
      for(let element of bankList){
        if(element.code == record.user.ownerBankAccount[0].accountBank){
          bankName = element.name
        }
      }
    }

    setModalContent(
    <div className='ModalContentLeft'>
          <br/>
          <p><b>หมายเลขเอกสาร: </b> {record.documentName}</p>
          <p><b>สาขา: </b> {record.branch.name}</p>
          <p><b>จำนวนเงินที่เบิก: </b> {record.totalAmount.toLocaleString('en',options)}</p>
          <p><b>สถานะ: </b> {record.status === 'approve' ? <b style={{color:'green'}}>อนุมัติ</b> : (record.status === 'pending' ? <b style={{color:'orange'}}>รอดำเนินการ</b>  : <b style={{color:'red'}}>ปฏิเสธ</b> )}</p>
          <br/>
          { record.status === 'approve' ? 
          <div>
            <p><b>หลักฐานไฟล์แนบ: </b> </p>
            <p> {record.attachFile1 ? <a href={record.attachFile1} target='_blank'> - ไฟล์แนบที่ 1</a> : '' } </p>
            <p> {record.attachFile2 ? <a href={record.attachFile2} target='_blank'> - ไฟล์แนบที่ 2</a> : '' } </p>
            <p> {record.attachFile3 ? <a href={record.attachFile3} target='_blank'> - ไฟล์แนบที่ 3</a> : '' } </p>
          </div>
          : (record.status === 'reject' ?
          <div>
            <p><b>เหตุผล: </b> {record.comment}</p>
          </div>
          : '')}
          <br/>
          <p><u>ข้อมูลบัญชีธนาคาร</u></p>
          <p><b>ธนาคาร: </b> {bankName}</p>
          <p><b>ชื่อบัญชี: </b> { record.user.ownerBankAccount ? record.user.ownerBankAccount[0].accountName : ''}</p>
          <p><b>เลขที่บัญชี: </b> { record.user.ownerBankAccount ? record.user.ownerBankAccount[0].accountNumber : ''}</p>
      
      
      </div>
    )
    setOpenModalQrcode(true)
  }

  const handleCancelQrcode = () => {
    setOpenModalQrcode(false)
  }
  
  const showEditWithdraw = async (record) =>{

    setFile1([])
    setFile2([])
    setFile3([])

    setFileUrl1(record.attachFile1 ? record.attachFile1 : '')
    setFileUrl2(record.attachFile2 ? record.attachFile2 : '')
    setFileUrl3(record.attachFile3 ? record.attachFile3 : '')


    if(record.status === 'pending'){
        setHideComment(true)
        setHideAttachFile(true)
    }else if(record.status === 'approve'){
        setHideComment(true)
        setHideAttachFile(false)
    }else if(record.status === 'reject'){
        setHideComment(false)
        setHideAttachFile(true)
    }


    form.setFieldsValue({ 
        documentId: record.id,
        documentName: record.documentName,
        branchName: record.branch.name,
        totalAmount: record.totalAmount,
        status: record.status,
        comment: record.comment,
        attachFile1: record.attachFile1,
        attachFile2: record.attachFile2,
        attachFile3: record.attachFile3,
    })

    setOpenModalEdit(true)
  }

  const getWithdrawList = async () =>{
    try {
      const response = await axios.get(`${API_URL}getWithdrawList`, {
        headers: {
          'Authorization': `bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })

      if(response.data.lenght !== 0){
        setEmptyList(false)
      }

      if(response.data){
        setWithdrawList(response.data)
      }

    } catch (error) {
      console.log('error', error)
    }
  }

  const changeStatusWithdraw = async (value) => {
        if(value === 'pending'){
            setHideComment(true)
            setHideAttachFile(true)
        }else if(value === 'approve'){
            setHideComment(true)
            setHideAttachFile(false)
        }else if(value === 'reject'){
            setHideComment(false)
            setHideAttachFile(true)
        }
  }

  const submitEditWithdraw = async (values) => {

    try {

      const formData = new FormData();

      if(file1.length !== 0){ formData.append('attachFile1', file1[0]) }
      if(file2.length !== 0){ formData.append('attachFile2', file2[0]) }
      if(file3.length !== 0){ formData.append('attachFile3', file3[0]) }
      formData.append('documentId',values.documentId)
      formData.append('status',values.status)
      formData.append('comment',values.comment)

      const response = await axios.post(`${API_URL}updateFanshieWithdraw`, formData,{
          headers: {
              'Authorization': `bearer ${token}`,
              'Content-Type': 'multipart/form-data'
          }
      })
      if (response.status === 200) {

        if(response.data.result === 'success'){
            messageApi.open({
              type: 'success',
              content: 'อัปเดตรายการเบิกเงินเรียบร้อย',
          });
          }else{
            messageApi.open({
              type: 'error',
              content: 'ไม่สามารถอัปเดตรายการเบิกเงินได้ กรุณาลองใหม่อีกครั้ง',
          });
         }
      }

      setEmptyList(true)
      setWithdrawList([])
      getWithdrawList()

  } catch (error) {
      console.log(error);
  }
  }

  const props1 = {
    multiple: false,
    onRemove: file => {
        setFile1([]);
    },
    beforeUpload: file => {
        if (file.type !== 'image/png' && file.type !== 'image/jpeg' && file.type !== 'application/pdf') {
          message.error(`รองรับไฟล์ PDF และไฟล์ภาพ PNG และ JPG`);
          return false;
        }
        setFile1(prev => [file]);
        return false;
    },
    fileList: file1,
  };

  const props2 = {
    multiple: false,
    onRemove: file => {
        setFile2([]);
    },
    beforeUpload: file => {
      if (file.type !== 'image/png' && file.type !== 'image/jpeg' && file.type !== 'application/pdf') {
        message.error(`รองรับไฟล์ PDF และไฟล์ภาพ PNG และ JPG`);
        return false;
      }
        setFile2(prev => [file]);
        return false;
    },
    fileList: file2,
  };

  const props3 = {
    multiple: false,
    onRemove: file => {
        setFile3([]);
    },
    beforeUpload: file => {
        if (file.type !== 'image/png' && file.type !== 'image/jpeg' && file.type !== 'application/pdf') {
          message.error(`รองรับไฟล์ PDF และไฟล์ภาพ PNG และ JPG`);
          return false;
        }
        setFile3(prev => [file]);
        return false;
    },
    fileList: file3,
  };



  useEffect(() => {
        getWithdrawList()
  }, [])

  return (
    <div className="fetch-event-table">
        {contextHolder}
        {
        <Modal
        title='รายการเบิกเงิน'
        centered
        open={openModalQrcode}
        footer={null}
        width={500}
        onCancel={handleCancelQrcode}
        >
        {modalContent}
        </Modal>
      }
        {
            <Modal
            title="จัดการรายการเบิกเงิน"
            centered
            open={openModalEdit}
            okText="ยืนยัน"
            cancelText="ยกเลิก"
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        submitEditWithdraw(values)
                        form.resetFields();
                        setOpenModalEdit(false);
                    })
                    .catch((errorInfo) => {
                        console.log('Failed:', errorInfo);
                    });
            }}
            onCancel={() => {
                form.resetFields();
                setOpenModalEdit(false);
            }}
            width={500} 
            >
            <Form
                form={form}
                layout="vertical"
            >
                <Form.Item name="documentId" hidden={true}>
                    <Input type='hidden'/>
                </Form.Item>

                <Form.Item name="documentName" label="หมายเลขเอกสาร" >
                    <Input type='text' disabled/>
                </Form.Item>

                <Form.Item name="branchName" label="ชื่อสาขา">
                    <Input type='text' disabled/>
                </Form.Item>

                <Form.Item name="totalAmount" label="ยอดเงินที่ต้องการเบิก">
                    <Input type='text' disabled/>
                </Form.Item>

                <Form.Item name="status" label="ผลการอนุมัติ"
                    rules={[
                        {
                            required: true,
                            message: 'กรุณาระบุผลการอนุมัติ',
                        },
                    ]}>
                    <Select onChange={changeStatusWithdraw}>
                        <Select.Option value={'pending'}>รอดำเนินการ</Select.Option>
                        <Select.Option value={'approve'}>อนุมัติ</Select.Option>
                        <Select.Option value={'reject'}>ปฏิเสธ</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item name="comment" label="เหตุผล" hidden={hideComment}>
                    <TextArea autoSize={{ minRows: 2, maxRows: 6 }}/>
                </Form.Item>
                <Form.Item name="attachFile1" label="หลักฐานการโอนเงิน 1" hidden={hideAttachFile}>
                  {fileUrl1 ? <a href={fileUrl1} target='_blank'>- ไฟล์แนบที่ 1</a> : ''} &nbsp;&nbsp;&nbsp;
                  <Upload {...props1}>
                    {fileUrl1 ? <Button icon={<UploadOutlined />}>เปลี่ยนไฟล์</Button> : <Button icon={<UploadOutlined />}>เลือกไฟล์</Button> }
                  </Upload>
                </Form.Item>
                <Form.Item name="attachFile2" label="หลักฐานการโอนเงิน 2" hidden={hideAttachFile}>
                  {fileUrl2 ? <a href={fileUrl2} target='_blank'>- ไฟล์แนบที่ 2</a> : ''} &nbsp;&nbsp;&nbsp;
                  <Upload {...props2}>
                    {fileUrl2 ? <Button icon={<UploadOutlined />}>เปลี่ยนไฟล์</Button> : <Button icon={<UploadOutlined />}>เลือกไฟล์</Button> }
                  </Upload>
                </Form.Item>
                <Form.Item name="attachFile3" label="หลักฐานการโอนเงิน 3" hidden={hideAttachFile}>
                  {fileUrl3 ? <a href={fileUrl3} target='_blank'>- ไฟล์แนบที่ 3</a> : ''} &nbsp;&nbsp;&nbsp;
                  <Upload {...props3}>
                    {fileUrl3 ? <Button icon={<UploadOutlined />}>เปลี่ยนไฟล์</Button> : <Button icon={<UploadOutlined />}>เลือกไฟล์</Button> }
                  </Upload>
                </Form.Item>
            </Form>
            </Modal>
        }
        { 
            !emptyList ?
            withdrawList && withdrawList.length > 0 
            ? ( <Table
                columns={columnsWithdraw}
                dataSource={withdrawList}
                pagination={false}
                scroll={{
                x: 400,
                }}
                rowKey="id"
            /> ) 
            : <center><div>ยังไม่มีรายการเบิกเงิน</div></center>
            : <center><Spin /></center>
        } 
    </div>
  )
}

export default ListFanshineWithdraw