import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Input, DatePicker, Select, message } from 'antd';
import axios from 'axios';
import { EditOutlined } from '@ant-design/icons'

import {fetchData} from './fetchUser'

const API_URL = process.env.REACT_APP_API_URL;

const EditUserButton = ({ username, branchList }) => {
    const [open, setOpen] = useState(false);
    const [branch, setBranch] = useState([]);
    const [form] = Form.useForm();
    const token = localStorage.getItem('Authentication');
    const [messageApi, contextHolder] = message.useMessage();

    const onSuccess = () => {
        messageApi.open({
            type: 'success',
            content: 'อัปเดตข้อมูลผู้ใช้สำเร็จ',
        });
    }
    useEffect(() => {
        if (open) {
            getData();
        }
    }, [open])

    useEffect(() => {
        getBranch();
    }, [])

    const getBranch = async () => {
        try {
            const response = await axios.get(`${API_URL}branchListSelect`,
                {
                    headers: { 'Authorization': `bearer ${token}` }
                })
            setBranch(response.data);

        } catch (error) {
            console.log(error);
        }
    }

    const getData = async () => {
        try {
            const response = await axios.get(`${API_URL}getUserDetail/${username}`, {
                headers: {
                    'Authorization': `bearer ${token}`,
                }
            })

            form.setFieldsValue({
                username: username,
                branch: branchList? branchList : [],
                role: response.data.role,
            });

        } catch (error) {
            console.error(error);
        }
    }

    const editUserInfo = async (values) => {
        try {
            const response = await axios.post(`${API_URL}editUser`, {
                username: username,
                password: values.password,
                role: values.role,
                branch: values.branch
            }, {
                headers: {
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            })
            if (response.status === 200) {
                fetchData()
                onSuccess();
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            {contextHolder}
            <Button type="primary" icon={<EditOutlined />} onClick={() => setOpen(true)} style={{ marginRight: '8px' }} />
            <Modal
                title="แก้ไขผู้ใช้งาน"
                centered
                open={open}
                okText="อัปเดต"
                cancelText="ยกเลิก"
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            editUserInfo(values)
                            form.resetFields()
                            setOpen(false);
                        })
                        .catch((errorInfo) => {
                            console.log('Failed:', errorInfo);
                        });
                }}
                onCancel={() => {
                    form.resetFields()
                    setOpen(false);
                }}
                width={400}
            >
                {/* <p>username: {username}</p> */}
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={{ username }}
                >
                    <Form.Item
                        name="username"
                        label="ชื่อผู้ใช้"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your username!',
                            },
                        ]}
                    >
                        <Input type="text" disabled />
                    </Form.Item>
                    <Form.Item
                        name="branch"
                        label="สาขา:"
                    >
                        {/* <Input type="text" /> */}
                        <Select
                            mode="multiple"
                            allowClear
                            optionFilterProp="label"
                        >
                            {branch.map(branchItem => (
                                <Select.Option key={branchItem.id} value={branchItem.id} label={branchItem.name}>{branchItem.name}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="password"
                        label="เปลี่ยนรหัสผ่าน:"
                    >
                        <Input type="password" />
                    </Form.Item>
                    <Form.Item name="role" label="สิทธิผู้ใช้:"
                        rules={[
                            {
                                required: true,
                                message: 'Please select your role!',
                            },
                        ]}
                    >
                        <Select>
                            <Select.Option value={'admin'}>admin</Select.Option>
                            <Select.Option value={'owner'}>owner</Select.Option>
                            <Select.Option value={'manager'}>manager</Select.Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};
export default EditUserButton;