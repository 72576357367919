import React, { useState, useEffect, useContext, useRef } from 'react'
import axios from 'axios'
import moment from 'moment';

import ChartDataLabels from 'chartjs-plugin-datalabels';
import annotationPlugin from 'chartjs-plugin-annotation';
import { DownloadOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx'



import { Card, Spin, Radio, Form, Select} from 'antd'
import { AppContext } from '../App';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ChartDataLabels,
    Title,
    Tooltip,
    Legend,
    annotationPlugin
  );

const IncomeThisWeek = (homeData) => {

    const token = localStorage.getItem('Authentication');
    const API_URL = process.env.REACT_APP_API_URL;

    const [loadingChart, setLoadingChart] = useState(true)
    const [incomeDetail, setincomeDetail] = useState("")
    const [chartReady, setChartReady] = useState(false)
    const [chartType, setChartType] = useState("week")
    const [maxValueOfChart, setMaxValueOfChart] = useState(2000)
    const [averageIncome, setAverageIncome] = useState(5000)
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [],
    });

    const [branch, setBranch] = useState([]);
    const [branchSelected, setBranchSelected] = useState('all');


    const [listDateData,setListDateData] = useState([])
    const [listIncomeData,setListIncomeData] = useState([])

    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
      const handleResize = () => setWidth(window.innerWidth);
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);


    
    const [form] = Form.useForm();



     useEffect(() => {
      
      setChartType(homeData.chartType)
      setChartData({
        labels: [],
        datasets: [],
      })
      setincomeDetail("")

      FetchIncomeThisWeek(homeData.chartType, branchSelected)

    },[homeData.chartType]);

    const options = {
        plugins: {
          legend: {
            position: 'top',
          },
          annotation: {
            annotations: {
              line1: {
                type: 'line',
                yMin: averageIncome,
                yMax: averageIncome,
                borderColor: 'rgb(255, 0, 0, 0.7)',
                borderWidth: 3,
                borderDash: ([5]), 
                label: {
                  display: false,
                  backgroundColor: 'black',
                  drawTime: 'afterDatasetsDraw',
                  content: averageIncome.toLocaleString()+" บาท"
                },
                enter({ element }, event) {
                    element.label.options.display = true;
                    return true; // force update
                },
                leave({ element }, event) {
                    element.label.options.display = false;
                    return true;
                }            
              },
            }
          },
          datalabels: {
            anchor: 'end',
            align: 'top',
            rotation: width < 1000 ? ( (chartType != 'week' ) ? 270 : 0 ) : 0,
            color: '#000000',
            font: {
              size: width < 1000 ? 9 : 12,
            },
            formatter: (value, context) => {
              const datasetArray = []
              context.chart.data.datasets.forEach((dataset) => {
                  if(dataset.data[context.dataIndex] != undefined){
                    datasetArray.push(dataset.data[context.dataIndex]);
                  }
              });

              function totalSum(total, datapoint){
                return total + datapoint
              }

              let sum = datasetArray.reduce(totalSum, 0);

              if(context.datasetIndex === datasetArray.length - 1){
                return sum.toLocaleString('en-US');
              }else{
                return '';
              }
              
            }
          }
        },
        responsive: true,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
            max: maxValueOfChart
          },
        },
    };


   const FetchIncomeThisWeek = async (chartTypeData,branchId) => {

      if(!branchId){branchId = ""}

        try {
            const response = await axios.get(`${API_URL}getIncomeChart/${chartTypeData}/${branchId}`, {
              headers: {
                'Authorization': `bearer ${token}`,
                'Content-Type': 'application/json'
              }
            })

            const listDate = response.data.listDate
            const listIncome = response.data.listIncome

            setListDateData(listDate)
            setListIncomeData(listIncome)

            var countIncome = listIncome.length
            var totalIncome = 0
            for(let incomeOne of listIncome){
              totalIncome = totalIncome + incomeOne.application + incomeOne.coin
            }

            setAverageIncome(parseInt(totalIncome/countIncome))

            if(chartTypeData === 'week' || chartTypeData === 'month' || chartTypeData === '30day'){
              setMaxValueOfChart(response.data.maxValueOfChart+2000)
            }else if(chartTypeData === 'year' || chartTypeData === '12month'){
              setMaxValueOfChart(response.data.maxValueOfChart+50000)
            }

            const data = {
                labels: listDate,
                datasets: [
                  {
                    label: 'หยอดเหรียญ',
                    data: listIncome.map((x) => x.coin),
                    backgroundColor: '#4bc0c0'
                  },
                  {
                    label: 'แอปพลิเคชั่น',
                    data: listIncome.map((x) => x.application),
                    backgroundColor: '#ee632f'
                  }
                ],
              };

            setincomeDetail(response.data.listIncome)
            setChartData(data);

            setLoadingChart(false)
            setChartReady(true)

            // if(chartTypeData === 'week' && homeData.branchSelectedIndex){
            //   await getIncomeTodayWeek(listDate,listIncome)
            // }else if(chartTypeData === 'month' && homeData.branchSelectedIndex){
            //   await getIncomeTodayMonth(listDate,listIncome)
            // }else if(chartTypeData === '30day' && homeData.branchSelectedIndex){
            //   await getIncomeToday30day(listDate,listIncome)
            // }else if(chartTypeData === 'year' && homeData.branchSelectedIndex){
            //   await getIncomeTodayYear(listDate,listIncome)
            // }else if(chartTypeData === '12month' && homeData.branchSelectedIndex){
            //   await getIncomeToday12Month(listDate,listIncome)
            // }

          } catch (error) {
            console.log('error', error)
          }
    }


  const getIncomeTodayWeek = async (listDate,listIncome) => {

    if(homeData.incomeThisDay.totalIncome || homeData.incomeThisDay.totalIncome == 0){

      var todate = moment().format('YYYY-MM-DD')
        var incomeTodayRecord = {
          application: parseInt(homeData.incomeThisDay.wallet) + parseInt(homeData.incomeThisDay.promptpay) + parseInt(homeData.incomeThisDay.truemoney),
          coin: homeData.incomeThisDay.coin,
          incomeDate: todate
        }

        if(!listDate.includes(todate)){

          listDate.push(todate)
          listIncome.push(incomeTodayRecord)
  
          const data = {
            labels: listDate,
            datasets: [
              {
                label: 'หยอดเหรียญ',
                data: listIncome.map((x) => x.coin),
                backgroundColor: '#4bc0c0'
              },
              {
                label: 'แอปพลิเคชั่น',
                data: listIncome.map((x) => x.application),
                backgroundColor: '#ee632f'
              }
            ],};
    
    
          setincomeDetail(listIncome)
          setChartData(data);
  
        }

      }
  }

  const getIncomeTodayMonth = async (listDate,listIncome) => {

    if(homeData.incomeThisDay.totalIncome || homeData.incomeThisDay.totalIncome == 0){

      var todate = moment().format('D')
      var incomeTodayRecord = {
        application: parseInt(homeData.incomeThisDay.wallet) + parseInt(homeData.incomeThisDay.promptpay) + parseInt(homeData.incomeThisDay.truemoney),
        coin: homeData.incomeThisDay.coin,
        incomeDate: todate
      }
     
      if(!listDate.includes(todate)){

        listDate.push(todate)
        listIncome.push(incomeTodayRecord)
  
        const data = {
          labels: listDate,
          datasets: [
            {
              label: 'หยอดเหรียญ',
              data: listIncome.map((x) => x.coin),
              backgroundColor: '#4bc0c0'
            },
            {
              label: 'แอปพลิเคชั่น',
              data: listIncome.map((x) => x.application),
              backgroundColor: '#ee632f'
            }
          ],
          };
    
    
        setincomeDetail(listIncome)
        setChartData(data);

      }

    }
  }

  const getIncomeToday30day = async (listDate,listIncome) => {

    if(homeData.incomeThisDay.totalIncome || homeData.incomeThisDay.totalIncome == 0){

      var todate = moment().format('D/M')
      var incomeTodayRecord = {
        application: parseInt(homeData.incomeThisDay.wallet) + parseInt(homeData.incomeThisDay.promptpay) + parseInt(homeData.incomeThisDay.truemoney),
        coin: homeData.incomeThisDay.coin,
        incomeDate: todate
      }
     
      if(!listDate.includes(todate)){

        listDate.push(todate)
        listIncome.push(incomeTodayRecord)

        const data = {
            labels: listDate,
            datasets: [
              {
                label: 'หยอดเหรียญ',
                data: listIncome.map((x) => x.coin),
                backgroundColor: '#4bc0c0'
              },
              {
                label: 'แอปพลิเคชั่น',
                data: listIncome.map((x) => x.application),
                backgroundColor: '#ee632f'
              }
            ],
          };


        setincomeDetail(listIncome)
        setChartData(data);
      }

    }
  }

  const getIncomeTodayYear = async (listDate,listIncome) => {

    if(homeData.incomeThisDay.totalIncome || homeData.incomeThisDay.totalIncome == 0){

      var thisDate = new Date();
      var thisMonthName = thisDate.toLocaleDateString('th-TH', {month: 'long',})

      var incomeTodayRecord = {
        application: parseInt(homeData.incomeThisDay.wallet) + parseInt(homeData.incomeThisDay.promptpay) + parseInt(homeData.incomeThisDay.truemoney),
        coin: homeData.incomeThisDay.coin,
      }

      for(let element of listIncome){
        if(element.incomeDate === thisMonthName){
          element.application = element.application + incomeTodayRecord.application
          element.coin = element.coin + incomeTodayRecord.coin
        }
      }

        const data = {
            labels: listDate,
            datasets: [
              {
                label: 'หยอดเหรียญ',
                data: listIncome.map((x) => x.coin),
                backgroundColor: '#4bc0c0'
              },
              {
                label: 'แอปพลิเคชั่น',
                data: listIncome.map((x) => x.application),
                backgroundColor: '#ee632f'
              }
            ],
          };

        setincomeDetail(listIncome)
        setChartData(data);

    }
  }

  const downloadData = async () =>{

    var incomeRecord;
    const incomeData = []

    const headerIncome = {
        incomeDate: "วันที่ / เดือน",
        incomeCoin: "รายได้จากเหรียญ",
        incomeApp: "รายได้แอปพลิเคชั่น",
        incomeTotal: "รายได้ทั้งหมด",
    }

    incomeData.push(headerIncome)

    for(let index = 0 ; index < listDateData.length ; index++){
      incomeRecord = {
        incomeDate: listDateData[index],
        incomeCoin: listIncomeData[index].coin,
        incomeApp: listIncomeData[index].application,
        incomeTotal: listIncomeData[index].application + listIncomeData[index].coin
      }

      incomeData.push(incomeRecord)
    }

    // add width
    var wscols = []
    for(let indexWidth = 0 ; indexWidth < 4 ; indexWidth++){
      wscols.push({wch:20})
    }

    const workbook = XLSX.utils.book_new()
    const worksheetIncome = XLSX.utils.json_to_sheet(incomeData, {skipHeader:true})
    worksheetIncome['!cols'] = wscols;


    XLSX.utils.book_append_sheet(workbook, worksheetIncome, 'รายงานแต่ละช่วงเวลา')
    XLSX.writeFile(workbook, 'รายงานรายได้แต่ละช่วงเวลา.xlsx')

  }

  const getIncomeToday12Month = async (listDate,listIncome) => {

    if(homeData.incomeThisDay.totalIncome || homeData.incomeThisDay.totalIncome == 0){

      var thisDate = new Date();
      var thisMonthName = thisDate.toLocaleDateString('th-TH', {month: 'long',})

      var incomeTodayRecord = {
        application: parseInt(homeData.incomeThisDay.wallet) + parseInt(homeData.incomeThisDay.promptpay) + parseInt(homeData.incomeThisDay.truemoney),
        coin: homeData.incomeThisDay.coin,
      }

  
      for(let element of listIncome){
        if(element.incomeDate === thisMonthName){
          element.application = element.application + incomeTodayRecord.application
          element.coin = element.coin + incomeTodayRecord.coin
        }
      }

        const data = {
            labels: listDate,
            datasets: [
              {
                label: 'หยอดเหรียญ',
                data: listIncome.map((x) => x.coin),
                backgroundColor: '#4bc0c0'
              },
              {
                label: 'แอปพลิเคชั่น',
                data: listIncome.map((x) => x.application),
                backgroundColor: '#ee632f'
              }
            ],
          };

        setincomeDetail(listIncome)
        setChartData(data);

    }
  }

    useEffect(() => {

        setBranchSelected(homeData.branchSelectedIndex)
        setLoadingChart(true)
        setChartReady(false)
        setChartData({
          labels: [],
          datasets: [],
        })
        setincomeDetail("")
        FetchIncomeThisWeek(chartType,homeData.branchSelectedIndex)

    }, [homeData.branchSelectedIndex])

    // useEffect(() => {
    //     if(chartType === 'week'){
    //       getIncomeTodayWeek(listDateData, listIncomeData)
    //     }else if(chartType === 'month'){
    //       getIncomeTodayMonth(listDateData, listIncomeData)
    //     }else if(chartType === '30day'){
    //       getIncomeToday30day(listDateData, listIncomeData)
    //     }else if(chartType === 'year'){
    //       getIncomeTodayYear(listDateData, listIncomeData)
    //     }else if(chartType === '12month'){
    //       getIncomeToday12Month(listDateData, listIncomeData)
    //     }
    // },[homeData.incomeThisDay])

    return (
        <Card title="รายได้แต่ละช่วงเวลา" extra={<DownloadOutlined onClick={()=>downloadData()}/>}>
            <center> <Spin spinning={loadingChart} /></center>
            {chartReady && <Bar options={options} data={chartData} height={width < 1000 ? 200 : 100}/> }
        </Card>
    )
}

export default IncomeThisWeek