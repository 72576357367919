import React, { useState, useEffect, useContext, useRef } from 'react'
import axios from 'axios'
import { Table, Input, Space, Button, message, Tag, Layout, Row, Col, Typography, Form, Modal, DatePicker, TimePicker, Select, Spin, Image, Upload } from 'antd'
import { SearchOutlined,ExclamationCircleOutlined, TagsOutlined, DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import moment from "moment";
import { AppContext } from '../App';
import dayjs from 'dayjs'

const { Header, Content } = Layout;
const { Title } = Typography;
const { RangePicker } = DatePicker;
const { TextArea } = Input;



const ListNews = () => {
  const { user } = useContext(AppContext);

  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem('Authentication');

  const [data, setData] = useState([])
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: Math.floor(window.screen.height/125),
  });

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [onCheckTransaction, setOnCheckTransaction] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();

  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openModalQrcode, setOpenModalQrcode] = useState(false)

  const [modalContent, setModalContent] = useState('')
  const [newsSelected, setNewsSelected] = useState('');
  const [messageConfirmDelete, setMessageConfirmDelete] = useState('ยืนยันการลบข่าวสาร');

  const [allData,setAllData] = useState([])
  const [activeData,setActiveData] = useState([])
  const [deactiveData,setDeactiveData] = useState([])

  const [allTotal,setallTotal] = useState(0)
  const [activeTotal,setActiveTotal] = useState(0)
  const [deactiveTotal,setDeactiveTotal] = useState(0)

  const [typeAll, setTypeAll] = useState('primary')
  const [typeActive, setTypeActive] = useState('')
  const [typeDeactive, setTypeDeactive] = useState('')

  const [paymentCheckout,setPaymentCheckout] = useState([])
  const [branch,setBranch] =useState([])


  const [requirePaymentCode, setRequirePaymentCode] = useState(false)
  const [newsStartDate, setNewsStartDate] = useState('')
  const [newsEndDate, setNewsEndDate] = useState('')

  const [bannerUrl, setBannerUrl] = useState('')
  const [bannerFile, setBannerFile] = useState([])

  const [coverUrl, setCoverUrl] = useState('')
  const [coverFile, setCoverFile] = useState([])


  const [form] = Form.useForm();

  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
  };
  
  const handleReset = (clearFilters) => {
      clearFilters();
      setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({

      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
          style={{
          padding: 8,
          }}
          onKeyDown={(e) => e.stopPropagation()}
      >
          <Input
          ref={searchInput}
          placeholder={`ค้นหาข่าวสาร`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
              marginBottom: 8,
              display: 'block',
          }}
          />
          <Space>
          <Button
              type="primary"
              icon={<SearchOutlined />}
              size="small"
              style={{
              width: 120,
              }}
              onClick={() => {
                  confirm({
                      closeDropdown: false,
                  });
                  setSearchText(selectedKeys[0]);
                  setSearchedColumn(dataIndex);
              }}
          >
              Search
          </Button>
          <Button
              onClick={() => {
                  clearFilters
                  handleReset(clearFilters)
                  confirm({
                      closeDropdown: false,
                  });
                  setSearchText(selectedKeys[0]);
                  setSearchedColumn(dataIndex);
              }}
              size="small"
              style={{
              width: 50,
              }}
          >
              Reset
          </Button>
          </Space>
      </div>
      ),
      filterIcon: (filtered) => (
      <SearchOutlined
          style={{
          color: filtered ? '#000000' : undefined,
          }}
      />
      ),
      onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
      onFilterDropdownOpenChange: (visible) => {
      if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
      }
      },
  });
  
  const columns = [
      {
          title: 'ภาพแบนเนอร์',
          width: 50,
          dataIndex: 'bannerUrl',
          key: 'bannerUrl',
          // fixed: 'left',
          render: (text, record) => (
              <div>
            {text ? <Image src={text} width={150} /> : ''}
          </div>
        ),
    },
    {
      title: 'ชื่อข่าวสาร',
      width: 150,
      dataIndex: 'name',
      key: 'name',
      // fixed: 'left',
      ...getColumnSearchProps('name'),
      render: (text, record) => (
        <div style={{ fontWeight: 600, color: "#3a9bdc" }} onClick={()=>showEditModal(record)}>
          {text}
        </div>
      )    
    },
    {
        title: 'ช่วงเวลาเผยแพร่',
        width: 150,
        dataIndex: 'publishStart',
        key: 'publishStart',
        // fixed: 'left',
        render: (text, record) => (
          <div>
            {text ?  moment(record.publishStart).format('DD/MM/YYYY') + " - " +  moment(record.publishEnd).format('DD/MM/YYYY') : ''}
          </div>
        ),
    },
    {
        title: 'แสดงที่หน้าแรก',
        width: 120,
        dataIndex: 'bannerStatus',
        key: 'bannerStatus',
        render: (text, record) => (
          <div className='divCenter'>
             {text === 'true' ? 'แสดง' : 'ไม่แสดง'}
          </div>
        ),
    },
    {
        title: 'แสดงที่หน้าข่าวสาร',
        width: 120,
        dataIndex: 'newListStatus',
        key: 'newListStatus',
        render: (text, record) => (
          <div className='divCenter'>
             {text === 'true' ? 'แสดง' : 'ไม่แสดง'}
          </div>
        ),
    },
    {
        title: 'สถานะ',
        width: 90,
        dataIndex: 'status',
        key: 'status',
        render: (status, record) => (
          <div className='divCenter'>
             {status === 'publish' ? <Tag color='green'>เปิดใช้งาน</Tag> : (status === 'unpublish' ? <Tag color='orange'>ปิดใช้งาน</Tag> : '')}
          </div>
        ),
    },

    {
      title: 'การจัดการ',
      key: 'action',
      // fixed: 'right',
      width: 120,
      render: (text, record) => (
        <div>
             { <div><Button type='primary' onClick={()=>showEditModal(record)}>แก้ไข</Button> <Button onClick={() => showDeleteModal(record)} danger>ลบ</Button> </div> }
        </div>
      ),
    },
  ]

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_URL}listNews`, {
        headers: {
          'Authorization': `bearer ${token}`,
          'Content-Type': 'application/json'
        },
        params: {
          page: pagination.current,
          pageSize: pagination.pageSize,
        },
      })

      const adjustedData = response.data.map((news) => ({
        ...news,
        key: news.id,
      }));

      setData(adjustedData)

      const activeData = adjustedData.filter((item) => item.status === 'publish')
      const deactiveData = adjustedData.filter((item) => item.status === 'unpublish')

      setAllData(adjustedData)
      setActiveData(activeData)
      setDeactiveData(deactiveData)


      setallTotal(adjustedData.length)
      setActiveTotal(activeData.length)
      setDeactiveTotal(deactiveData.length)


      setPagination((prevPagination) => {
        return {
          ...prevPagination,
          total: response.total,
          pageSize: Math.floor(window.screen.height / 90),
        };
      });

    } catch (error) {
      console.log('error', error)
    }
  }
  
    const addNews = async (values) => {

        const formData = new FormData();

        if(bannerFile.length !== 0){ formData.append('bannerFile', bannerFile[0]) }
        if(coverFile.length !== 0){ formData.append('coverFile', coverFile[0]) }
        formData.append('name',values.newsName)
        formData.append('description',values.description)
        formData.append('newsUrl',values.newsUrl)
        formData.append('bannerStatus',values.bannerStatus)
        formData.append('newListStatus',values.newListStatus)
        formData.append('publishStart', newsStartDate)
        formData.append('publishEnd', newsEndDate)
        formData.append('status',values.status)

        try {
            const response = await axios.post(`${API_URL}createNews`, formData , {
                headers: {
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                },
            })
    
            if (response.status === 200) {
                messageApi.open({
                    type: 'success',
                    content: 'เพิ่มข่าวสารเรียบร้อย',
                });
                fetchData()
            }

            setCoverFile([])
            setBannerFile([])
        } catch (error) {
            console.log(error);

        }

    }
    
    const editNews = async (values) => {

        const formData = new FormData();


        if(bannerFile.length !== 0){ formData.append('bannerFile', bannerFile[0]) }
        if(coverFile.length !== 0){ formData.append('coverFile', coverFile[0]) }
        formData.append('newsId',values.newsId)
        formData.append('name',values.newsName)
        formData.append('description',values.description)
        formData.append('newsUrl',values.newsUrl)
        formData.append('bannerStatus',values.bannerStatus)
        formData.append('newListStatus',values.newListStatus)
        formData.append('publishStart', newsStartDate)
        formData.append('publishEnd', newsEndDate)
        formData.append('status',values.status)

        try {
            const response = await axios.post(`${API_URL}editNews`, formData , {
                headers: {
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            })
    
            if (response.status === 200) {
                messageApi.open({
                    type: 'success',
                    content: 'แก้ไขข่าวสารเรียบร้อย',
                });
                fetchData()
            }
        } catch (error) {
            console.log(error);

        }

    }

    const deleteNews = async () => {
        try {
            const response = await axios.post(`${API_URL}deleteNews`, {
                newsId: newsSelected,
            },{
              headers: {
                'Authorization': `bearer ${token}`,
                'Content-Type': 'application/json'
              }
            })
      
            if(response.status === 200){
                messageApi.open({
                    type: 'success',
                    content: 'ลบข่าวสารเรียบร้อย',
                });
                fetchData()
            }

          } catch (error) {
            console.log('error', error)
          }
    }

  const showCreateModal = async () => {
    setRequirePaymentCode(false)
    setOpenAddModal(true)
    setBannerUrl('')
    setBannerFile([])
    setCoverFile([])
  }

  const showEditModal = async (record) => {

    setNewsStartDate(record.publishStart)
    setNewsEndDate(record.publishEnd) 

    form.setFieldsValue({
        newsId: record.id,
        newsName: record.name,
        description: record.description,
        newsUrl: record.newsUrl,
        bannerStatus: record.bannerStatus,
        newListStatus: record.newListStatus,
        status: record.status,
        publishDate: [dayjs(record.publishStart),dayjs(record.publishEnd)],
    });
    setBannerUrl(record.bannerUrl)
    setCoverUrl(record.coverUrl)
    setBannerFile([])
    setCoverFile([])

    setOpenEditModal(true)
  }

  const showDeleteModal = async (record) => {
    setNewsSelected(record.id)
    setOpenDeleteModal(true)
  }

  const setNewsDate = (value,dateString) =>{
    setNewsStartDate(dateString[0])
    setNewsEndDate(dateString[1])
  }

  const setNewsTime = async (time) => {
    if(time){
        setNewsStartTime(dayjs(time[0]).format('HH:mm'));
        setNewsEndTime(dayjs(time[1]).format('HH:mm'));    
    }
  }

  const setDisplayData = function (status){
    if(status === 'all'){
      setData(allData)

      setTypeAll('primary')
      setTypeActive('')
      setTypeDeactive('')
    }else if(status === 'active'){
        setData(activeData)

        setTypeAll('')
        setTypeActive('primary')
        setTypeDeactive('')
    }else if(status === 'deactive'){
        setData(deactiveData)

        setTypeAll('')
        setTypeActive('')
        setTypeDeactive('primary')
    }
  }

  const props1 = {
    multiple: false,
    onRemove: file => {
        setBannerFile([]);
    },
    beforeUpload: file => {
        if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
            message.error(`รองรับไฟล์ภาพ PNG และ JPG`);
            return false;
        }
        setBannerFile(prev => [file]);
        return false;
    },
    fileList: bannerFile,
  };

  const props2 = {
    multiple: false,
    onRemove: file => {
        setCoverFile([]);
    },
    beforeUpload: file => {
        if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
            message.error(`รองรับไฟล์ภาพ PNG และ JPG`);
            return false;
        }
        setCoverFile(prev => [file]);
        return false;
    },
    fileList: coverFile,
  };


  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div className="fetch-event-table">
      {contextHolder}
      {
        <Modal
        title="เพิ่มข่าวสาร"
        centered
        open={openAddModal}
        okText="ยืนยัน"
        cancelText="ยกเลิก"
        onOk={() => {
            form
                .validateFields()
                .then((values) => {
                    addNews(values)
                    form.resetFields();
                    setOpenAddModal(false);
                })
                .catch((errorInfo) => {
                    console.log('Failed:', errorInfo);
                });
        }}
        onCancel={() => {
            form.resetFields();
            setOpenAddModal(false);
        }}
        width={400}
    >
        <Form
            form={form}
            layout="vertical"
        >
            <Form.Item name="newsName" label="หัวข้อข่าวสาร"
                rules={[
                    {
                        required: true,
                        message: 'กรุณากรอกหัวข้อข่าวสาร',
                    },
                ]}>
                <Input type='text'/>
            </Form.Item>
            <Form.Item name="description" label="รายละเอียด (แสดงในหน้าข่าวสาร)"
                        rules={[
                            {
                                required: true,
                                message: 'กรุณากรอกรายละเอียด',
                            },
                        ]}>
                <TextArea autoSize={{ minRows: 2, maxRows: 6 }}/>
              </Form.Item>

            <Form.Item name="bannerUrl" label="ลิงค์ภาพ Banner (ขนาดแนะนำ 390x120 px)">
                <Upload {...props1}>
                    <Button icon={<UploadOutlined />}>เลือกภาพ</Button>
                </Upload>
              </Form.Item>

              <Form.Item name="coverUrl" label="ลิงค์ภาพ Cover (ขนาดแนะนำ 390x240 px)">
              <Upload {...props2}>
                    <Button icon={<UploadOutlined />}>เลือกภาพ</Button>
                </Upload>              
                </Form.Item>

              <Form.Item name="newsUrl" label="ลิงค์ข่าวสาร"
                        rules={[
                            {
                                required: true,
                                message: 'กรุณากรอกลิงค์ข่าวสาร',

                            },
                        ]}>
                        <Input type='text' />
              </Form.Item>

              <Form.Item name="bannerStatus" label="แสดง Banner ในหน้าแรก"
                    rules={[
                        {
                            required: true,
                            message: 'กรุณาระบุสถานะการแสดง Banner',

                        },
                ]}>
                <Select>
                    <Select.Option value="true">แสดง</Select.Option>
                    <Select.Option value="false">ไม่แสดง</Select.Option>

                </Select>
              </Form.Item>

              <Form.Item name="newListStatus" label="แสดงในหน้าข่าวสาร"
                    rules={[
                        {
                            required: true,
                            message: 'กรุณาระบุสถานะการแสดงข่าวสาร',

                        },
                ]}>
                <Select>
                    <Select.Option value="true">แสดง</Select.Option>
                    <Select.Option value="false">ไม่แสดง</Select.Option>

                </Select>
              </Form.Item>

              <Form.Item name="publishDate" label="ช่วงเวลาการแสดงข่าวสาร"
                        rules={[
                            {
                                required: true,
                                message: 'กรุณาระบุช่วงเวลาการแสดงข่าวสาร',

                            },
                        ]}>
                <RangePicker onChange={setNewsDate}/>
              </Form.Item>
              <Form.Item name="status" label="สถานะ"
                        rules={[
                            {
                                required: true,
                                message: 'กรุณาระบุสถานะ',
                            },
                        ]}>
               <Select>
                    <Select.Option value="publish">เปิดใช้งาน</Select.Option>
                    <Select.Option value="unpublish">ปิดใช้งาน</Select.Option>
                </Select>
              </Form.Item>

        </Form>
        </Modal>
      }
      {
        <Modal
        title="แก้ไขข่าวสาร"
        centered
        open={openEditModal}
        okText="ยืนยัน"
        cancelText="ยกเลิก"
        onOk={() => {
            form
                .validateFields()
                .then((values) => {
                    editNews(values)
                    form.resetFields();
                    setOpenEditModal(false);
                })
                .catch((errorInfo) => {
                    console.log('Failed:', errorInfo);
                });
        }}
        onCancel={() => {
            form.resetFields();
            setOpenEditModal(false);
        }}
        width={400}
    >
        <Form
            form={form}
            layout="vertical"
        >
            <Form.Item name="newsId" hidden={true}> <Input type='hidden'/> </Form.Item>           
            <Form.Item name="newsName" label="หัวข้อข่าวสาร"
                rules={[
                    {
                        required: true,
                        message: 'กรุณากรอกหัวข้อข่าวสาร',
                    },
                ]}>
                <Input type='text'/>
            </Form.Item>
            <Form.Item name="description" label="รายละเอียด (แสดงในหน้าข่าวสาร)"
                        rules={[
                            {
                                required: true,
                                message: 'กรุณากรอกรายละเอียด',
                            },
                        ]}>
                <TextArea autoSize={{ minRows: 2, maxRows: 6 }}/>
              </Form.Item>

            <Form.Item name="bannerUrl" label="ลิงค์ภาพ Banner (ขนาดแนะนำ 390x120 px)">
                <Upload {...props1}>
                    { bannerUrl ? <img src={bannerUrl} alt="logo" style={{ width: '100%', cursor:'pointer' }} /> : ''}
                    { bannerUrl ? <Button icon={<UploadOutlined />}>เปลี่ยนภาพ</Button> : <Button icon={<UploadOutlined />}>เลือกภาพ</Button>}
                    
                </Upload>

              </Form.Item>

              <Form.Item name="coverUrl" label="ลิงค์ภาพ Cover (ขนาดแนะนำ 390x240 px)">
                <Upload {...props2}>
                    { coverUrl ? <img src={coverUrl} alt="logo" style={{ width: '100%', cursor:'pointer' }} /> : ''}
                    { coverUrl ? <Button icon={<UploadOutlined />}>เปลี่ยนภาพ</Button> : <Button icon={<UploadOutlined />}>เลือกภาพ</Button>}
                </Upload>
              </Form.Item>

              <Form.Item name="newsUrl" label="ลิงค์ข่าวสาร"
                        rules={[
                            {
                                required: true,
                                message: 'กรุณากรอกลิงค์ข่าวสาร',

                            },
                        ]}>
                        <Input type='text' />
              </Form.Item>

              <Form.Item name="bannerStatus" label="แสดง Banner ในหน้าแรก"
                    rules={[
                        {
                            required: true,
                            message: 'กรุณาระบุสถานะการแสดง Banner',

                        },
                ]}>
                <Select>
                    <Select.Option value="true">แสดง</Select.Option>
                    <Select.Option value="false">ไม่แสดง</Select.Option>

                </Select>
              </Form.Item>

              <Form.Item name="newListStatus" label="แสดงในหน้าข่าวสาร"
                    rules={[
                        {
                            required: true,
                            message: 'กรุณาระบุสถานะการแสดงข่าวสาร',

                        },
                ]}>
                <Select>
                    <Select.Option value="true">แสดง</Select.Option>
                    <Select.Option value="false">ไม่แสดง</Select.Option>

                </Select>
              </Form.Item>

              <Form.Item name="publishDate" label="ช่วงเวลาการแสดงข่าวสาร"
                        rules={[
                            {
                                required: true,
                                message: 'กรุณาระบุช่วงเวลาการแสดงข่าวสาร',

                            },
                        ]}>
                <RangePicker onChange={setNewsDate}/>
              </Form.Item>
              <Form.Item name="status" label="สถานะ"
                        rules={[
                            {
                                required: true,
                                message: 'กรุณาระบุสถานะ',
                            },
                        ]}>
               <Select>
                    <Select.Option value="publish">เปิดใช้งาน</Select.Option>
                    <Select.Option value="unpublish">ปิดใช้งาน</Select.Option>
                </Select>
              </Form.Item>
        </Form>
        </Modal>
      }
       <Modal
            title={
                <>
                    <ExclamationCircleOutlined style={{ color: 'red', marginRight: '8px' }} />
                    ลบข่าวสาร
                </>
            }
            centered
            open={openDeleteModal}
            okText="ยืนยัน"
            cancelText="ยกเลิก"
            onOk={() => {
                deleteNews()
                setOpenDeleteModal(false)
            }}
            onCancel={() => {
                setOpenDeleteModal(false);
            }}
            width={300}
        >
            {messageConfirmDelete}
        </Modal>
      <Row>
            <Col span={21} >
                <Title level={3} ><TagsOutlined /> ข่าวสาร</Title>
            </Col>
                <Title level={3} ><Col span={2}><Button type='primary' onClick={()=>showCreateModal()}>เพิ่มข่าวสาร</Button></Col></Title>
        </Row>
        <Row>
            <Col span={24} >
            สถานะข่าวสาร : <br /><Button type={typeAll} onClick={()=> setDisplayData('all')}>ทั้งหมด ({allTotal})</Button>  &nbsp;
                        <Button type={typeActive} onClick={()=> setDisplayData('active')}>ใช้งานอยู่ ({activeTotal})</Button> &nbsp;
                        <Button type={typeDeactive} onClick={()=> setDisplayData('deactive')}>ปิดใช้งาน ({deactiveTotal})</Button>  &nbsp;


            </Col>
        </Row>
        <Row>
            <Col span={24} >
                {data && data.length > 0 ? (
                    <Table
                    columns={columns}
                    dataSource={data}
                    pagination={pagination}
                    onChange={(newPagination) => setPagination(newPagination)}
                    scroll={{
                        x: 400,
                    }}
                    rowKey="id"
                    />
                ) : (
                    data.length == 0 ? 'ไม่พบข้อมูล' : <Spin />
                )}
            </Col>
        </Row>
    </div>
  )
}

export default ListNews