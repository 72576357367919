import React, { useState, useContext, useEffect } from 'react';
import {
  CarryOutOutlined,
  FileOutlined,
  HomeOutlined,
  UploadOutlined,
  PieChartOutlined,
  LogoutOutlined,
  UsergroupAddOutlined,
  TransactionOutlined,
  DatabaseOutlined,
  HistoryOutlined,
  WalletOutlined,
  ShopOutlined,
  AccountBookOutlined,
  UserOutlined,
  CopyrightOutlined,
  ConsoleSqlOutlined,
  SelectOutlined,
  DeploymentUnitOutlined,
  CloudDownloadOutlined,
  BarcodeOutlined,
  TagsOutlined,
  ReadOutlined,
  BankOutlined,
  TruckOutlined
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import OrigamiLogo from './origamiLogo';
import { AppContext } from '../App';

const { Sider } = Layout;

const MenuSide = () => {
  const { logout, user } = useContext(AppContext);
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState(null); // Set a default value

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  useEffect(() => {
    if(width < 1000){
      setCollapsed(false)
    }
  }, []);




  const commonItems = [
    {
      label: <Link to="/home">หน้าแรก</Link>,
      icon: <HomeOutlined />,
      key: '1',
      to: '/home'
    },
    {
      label: <Link to="/machine">เครื่องซัก</Link>,
      icon: <DatabaseOutlined />,
      key: '2',
      to: '/machine'
    },
    {
      label: <Link to="/laundry">ประวัติการใช้งาน</Link>,
      icon: <HistoryOutlined />,
      key: '3',
      to: '/laundry'
    },
    {
      label: <Link to="/branchs">สาขา</Link>,
      icon: <ShopOutlined />,
      key: '4',
      to: '/branchs'
    },
    {
      label: <Link to="/promotionBranch">โปรโมชั่นสาขา</Link>,
      icon: <TagsOutlined />,
      key: '8',
      to: '/promotionBranch'
    },
    {
      label: <Link to="/saleReport">รายงานยอดขาย</Link>,
      icon: <AccountBookOutlined />,
      key: '5',
      to: '/saleReport'
    },
    {
      label: '',
      key: '10',
      to: '#'
    },
    {
      label: <Link to="/" onClick={() => handleLogout()}>ออกจากระบบ</Link>,
      icon: <LogoutOutlined />,
      key: '20',
      to: '/'
    }

  ];

  const withdrawTab = {
      label: <Link to="/withdraw">เบิกเงิน</Link>,
      icon: <SelectOutlined />,
      key: '6',
      to: '/withdraw'
  }

  const bankAccountTab = {
    label: <Link to="/bankAccount">บัญชีธนาคาร</Link>,
    icon: <BankOutlined />,
    key: '22',
    to: '/bankAccount'
}


  const transactionTab = {
    label: <Link to="/transaction">ประวัติการชำระ</Link>,
    icon: <TransactionOutlined />,
    key: '17',
    to: '/transaction'
  }

  const userTab = {
    label: <Link to="/user">ผู้ใช้</Link>,
    icon: <UsergroupAddOutlined />,
    key: '18',
    to: '/user'
  };

  const customerTab = {
    label: <Link to="/customer">ลูกค้า</Link>,
    icon: <UserOutlined />,
    key: '19',
    to: '/customer'
  };

  const walletTab = {
    label: <Link to="/wallet">กระเป๋าเงิน</Link>,
    icon: <WalletOutlined />,
    key: '16',
    to: '/wallet'
  };

  const pointTab = {
    label: <Link to="/point">กระเป๋าคะแนน</Link>,
    icon: <CopyrightOutlined />,
    key: '14',
    to: '/point'
  };

  const fanshineTab = {
    label: <Link to="/fanshine">แฟรนไชส์</Link>,
    icon: <DeploymentUnitOutlined />,
    key: '13',
    to: '/fanshine'
  }

  const fanshineWithdraw = {
    label: <Link to="/withdrawList">รายการเบิกเงิน</Link>,
    icon: <CloudDownloadOutlined />,
    key: '11',
    to: '/withdrawList'
  }


  const couponTab = {
    label: <Link to="/coupon">คูปอง</Link>,
    icon: <BarcodeOutlined />,
    key: '12',
    to: '/coupon'
  }

  const accountReportTab = {
    label: <Link to="/accountReport">รายงานบัญชี</Link>,
    icon: <ConsoleSqlOutlined />,
    key: '15',
    to: '/accountReport'
  };

  const promotionTab = {
    label: <Link to="/promotion">โปรโมชั่น</Link>,
    icon: <TagsOutlined />,
    key: '9',
    to: '/promotion'
  };

  const newsTab = {
    label: <Link to="/news">ข่าวสาร</Link>,
    icon: <ReadOutlined />,
    key: '21',
    to: '/news'
  };

  const commissionTab = {
    label: <Link to="/commission">คอมมิชชั่น</Link>,
    icon: <TruckOutlined />,
    key: '23',
    to: '/commission'
  };



  const items = [...commonItems];

  if (user?.role == 'admin') {
    // items.unshift(adminTab); 
    const logoutIndex = items.findIndex(item => item.key === '7');
    items.splice(logoutIndex, 0, customerTab);
    items.splice(logoutIndex, 0, walletTab);
    items.splice(logoutIndex, 0, pointTab);
    items.splice(logoutIndex, 0, couponTab);
    items.splice(logoutIndex, 0, promotionTab);
    items.splice(logoutIndex, 0, newsTab);
    items.splice(logoutIndex, 0, transactionTab);
    items.splice(logoutIndex, 0, fanshineTab);
    items.splice(logoutIndex, 0, fanshineWithdraw);
    items.splice(logoutIndex, 0, commissionTab);
    items.splice(logoutIndex, 0, userTab);
    items.splice(logoutIndex, 0, accountReportTab);
  }

  if (user?.role == 'owner'){
    const logoutIndex = items.findIndex(item => item.key === '6');
    items.splice(logoutIndex, 0, withdrawTab);
    items.splice(logoutIndex, 0, bankAccountTab);
  }

  const handleLogout = () => {
    logout();
  }

  useEffect(() => {
    const newSelectedKey = items.find(item => location.pathname.startsWith(item.to))?.key || '1';
    setSelectedKey(newSelectedKey);
  }, [location.pathname, user])

  return (
    <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)} breakpoint="lg" style={{background: 'linear-gradient(rgb(189, 24, 139) 0%, rgb(125, 28, 125) 35%, rgb(65, 26, 111) 100%)'}} >
      <div className="logo-vertical">
        <OrigamiLogo collapsed={collapsed} />
      </div>
      <Menu style={{backgroundColor:"#00000000",fontWeight:"600"}} theme='dark' defaultSelectedKeys={['1']} selectedKeys={[selectedKey]} mode="inline" items={items} />
    </Sider>
  );
};

export default MenuSide;