import React, { useState } from 'react';
import { Button, Modal, message } from 'antd';
import axios from 'axios';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'

import {fetchData} from './fetchUser'

const API_URL = process.env.REACT_APP_API_URL;


const DeleteUserButton = ({ username }) => {
    const messageConfirm = `ยืนยันการลบผู้ใช้ ${username}?`
    const [open, setOpen] = useState(false);
    const token = localStorage.getItem('Authentication');
    const [messageApi, contextHolder] = message.useMessage();

    const onSuccess = () => {
        messageApi.open({
            type: 'success',
            content: 'ลบผู้ใช้สำเร็จ',
        });
    }

    const deleteUser = async () => {
        try {
            const response = await axios.delete(`${API_URL}deleteUser/${username}`, {
                headers: {
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            })

            if (response.status === 200) {
                onSuccess();
                fetchData()
                setOpen(false);
            }
        } catch (error) {
            console.log('error', error);
        }
    }

    return (
        <>
            {contextHolder}
            <Button type="primary" icon={<DeleteOutlined />} onClick={() => setOpen(true)}
                style={{ marginRight: '8px', backgroundColor: '#ce2029' }} />
            <Modal
                title={
                    <>
                        <ExclamationCircleOutlined style={{ color: 'red', marginRight: '8px' }} />
                        ลบผู้ใช้งาน
                    </>
                }
                centered
                open={open}
                okText="ยืนยัน"
                cancelText="ยกเลิก"
                onOk={() => {
                    deleteUser()
                    setOpen(false)
                }}
                onCancel={() => {
                    setOpen(false);
                }}
                width={300}
            >
                {messageConfirm}
            </Modal>
        </>
    );
};
export default DeleteUserButton;