import React, { useContext, useEffect, useState } from "react";

import { Layout, Typography, Row, Col, Button} from "antd";
import { DatabaseOutlined, MenuOutlined, DashboardOutlined } from '@ant-design/icons';

import MenuSide from "../components/menuSide";
import MachineUsageReport from "../components/machineUsageReport"

import { AppContext } from '../App';

const { Header, Content } = Layout;
const { Title } = Typography;

const MachineUsageReportPage = () => {

  const [width, setWidth] = useState(window.innerWidth);
  const [menuDisplay, setMenuDisplay] = useState(false);

  const handleMenu = (value) => {
    setMenuDisplay(value)
  }

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);


    return (
      width < 1000 ? 

      <Layout className="fullHeight">
          {menuDisplay ? <MenuSide/> : <MenuOutlined onClick={()=>handleMenu(true)} style={{margin:20}}/>}
          <Content className="contentSection" onClick={()=>handleMenu(false)}>
            <Row>
              <Col span={22} ><Title level={3} ><DashboardOutlined /> รายงานการใช้งานเครื่อง</Title></Col>
            </Row>
            <br />
            <Row>
              <Col span={22} ><MachineUsageReport /></Col>
            </Row>
            
          </Content>
          
      </Layout>
      :
      <Layout className="fullHeight">
          <MenuSide />
          <Content className="contentSection">
            <Row>
              <Col span={1} ></Col>
              <Col span={22} ><Title level={3} ><DashboardOutlined /> รายงานการใช้งานเครื่อง</Title></Col>
              <Col span={1} ></Col>
            </Row>
            <br />
            <Row>
              <Col span={1} ></Col>
              <Col span={22} ><MachineUsageReport /></Col>
              <Col span={1} ></Col>
            </Row>
            
          </Content>
          
      </Layout>
    )
  };
  
  export default MachineUsageReportPage;
  